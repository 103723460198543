import axios, { AxiosResponse } from 'axios'
import {  URL_FEEDER_LOG_SHEET } from 'src/apis'


export const logSheetApiData = (query: any): Promise<any> => {
  return axios
    .get(`${URL_FEEDER_LOG_SHEET}?${query}`)
    .then(async (d: any) => d?.data)
    .catch((e: any) => e?.response?.data)
}







