import { AlertCircle, Gauge } from 'lucide-react'
import millify from 'millify'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import TableComponent from 'src/components/TableComponent'
import { UsersListLoading } from '../../../../../components/ui/UsersListLoading'
import {
    Card,
    CardFooter
} from "../../../../../components/ui/card"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../../components/ui/dialog'
import { convertMinutesToHoursAndMinutes, convertToCamelCase, getFormatToDateTimeWithoutSecondsTogether, getStartDateAndEndDateOfMonth, print } from '../../../../../helpers'
import { FeederCardsMainState, QueryRequestProvider, QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from '../Context'
import { viewDetails } from '../Context/core/_request'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getFeederCardsReducerState } from 'src/redux/Reducers/feederCardsReducer'
import { fetchFeederLogTableAsync, setEndDate, setFeeder, setStartDate } from 'src/redux/Reducers/feederLogTableReducer'
import { getFilterReducerState } from 'src/redux/Reducers/filterReducer'
import { fetchFeederPeakMonitoringAsync, setDate, setPeakMonitoringFeeder } from 'src/redux/Reducers/feederPeakMonitoringReducer'
import { fetchFeederProjectionAsync, setProjectionEndDate, setProjectionFeeder, setProjectionStartDate } from 'src/redux/Reducers/feederProjectionReducer'
import { fetchFeederPimAsync, setPimEndDate, setPimFeeder, setPimStartDate } from 'src/redux/Reducers/feederPimReducer'

type Props = {}

const MoreDetails = (data: any) => {
    // console.log(data?.data?.feeder_mg_id);
    const [viewMore, setViewMore] = useState<any>([])
    const excludedKeys = ['district_id', 'is_change_request', 'multi_factor', 'sc_id', 'state_id', 'status', 'updatedAt', '_id', 'createdAt', 'feeder_id', 'subdivision_id', 'manager_id', 'src_feeder_id', 'src_substation_id', 'src_transformer_id', 'tcn_power_transformer_id', 'tcn_substation_id'];
    const [isOpen, setisOpen] = useState("hide")

    const rowData = viewMore && Object.entries(viewMore).filter(([key]) => !excludedKeys.includes(key));


    useEffect(() => {
        if (isOpen == "show") {
            fetchDetails()
        }
        return () => { }
    }, [isOpen])


    const fetchDetails = async () => {
        const apiResp = await viewDetails(data?.data && data?.data?.feeder_mg_id);
        // console.log({ apiResp });
        let moreData: any = apiResp && apiResp?.data
        // console.log({ moreData });
        setViewMore(moreData)

    }

    return <>
        <button onClick={() => setisOpen("show")} className="bg-transparent hover:text-blue-400 text-blue-700 font-semibold py-2 px-4 text-xs underline">
            View More Details
        </button>
        <Dialog open={isOpen == "show"} onOpenChange={(e) => setisOpen(e ? "show" : "hide")} >
            <DialogContent className="lg:max-w-[1025px]">
                <DialogHeader>
                    <DialogTitle>More Details</DialogTitle>
                </DialogHeader>

                <div className='grid grid-cols-6 gap-2'>
                    {rowData && rowData?.map(([key, values]: any, index: any) => (
                        <React.Fragment key={index}>
                            <div className='col-span-1 text-sm'>{key && convertToCamelCase(key)} :</div>
                            <div className='col-span-1 text-sm font-bold'>{
                                key == "createdAt" ? getFormatToDateTimeWithoutSecondsTogether(values) : values ? String(values) : "-"
                            }</div>
                        </React.Fragment>
                    ))}
                </div>

                <DialogFooter>
                    {/* <Button type="submit">Save changes</Button> */}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    </>
}

const Main = (props: Props) => {

    const { state, updateState } = FeederCardsMainState();
    // const isLoading = useQueryResponseLoading();
    const apiResp: any = useQueryResponseData();
    const dispatch = useDispatch()

    const { district_id, _date } = useSelector((state: RootState) => getFilterReducerState(state))
    const { tableData, isLoading } = useSelector((state: RootState) => getFeederCardsReducerState(state))

    let data: any = useMemo(() => tableData || [], [tableData])
    const navigate = useNavigate()

    // print({ data, apiResp })
    // print({ state })

    const { isError, message } = useQueryResponse();

    React.useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        return () => { }
    }, [isError])


    const toTableData = (item: any) => {
        // console.log(item);

        return [{
            type: "A",
            voltage: item.V_A,
            current: item.A_A,
        },
        {
            type: "B",
            voltage: item.V_B,
            current: item.A_B,
        },
        {
            type: "C",
            voltage: item.V_C,
            current: item.A_C,
        },
        {
            type: "AVG",
            voltage: item.V_AVG,
            current: item.A_AVG,
        }]
    }

    const loadPim = (item: any) => {
        let start_date = _date && getStartDateAndEndDateOfMonth(_date).firstDate
        let end_date = _date && getStartDateAndEndDateOfMonth(_date).lastDate

        // window.open(`/live-pim?feeder_code=${item?.feeder_code && item?.feeder_code}&start_date=${start_date}&end_date=${end_date}&mg_id=${item?.feeder_mg_id && item?.feeder_mg_id}`, '_blank')
        navigate(`/dashboard/feeder/analysis/live-pim?feeder_code=${item?.feeder_code && item?.feeder_code}&mg_id=${item?.feeder_mg_id && item?.feeder_mg_id}`)

        dispatch(setPimFeeder((item?.feeder_code && item?.feeder_code)))
        dispatch(setPimStartDate(start_date))
        dispatch(setPimEndDate(end_date))
        dispatch(fetchFeederPimAsync())
    }

    const loadLogSheet = (item: any) => {
        let start_date = _date && getStartDateAndEndDateOfMonth(_date).firstDate
        let end_date = _date && getStartDateAndEndDateOfMonth(_date).lastDate

        navigate(`/dashboard/feeder/analysis/log-sheet?feeder_code=${item?.feeder_code && item?.feeder_code}&mg_id=${item?.feeder_mg_id && item?.feeder_mg_id}`)

        dispatch(setFeeder((item?.feeder_code && item?.feeder_code)))
        dispatch(setStartDate(start_date))
        dispatch(setEndDate(end_date))
        dispatch(fetchFeederLogTableAsync())

    }

    const loadPeakMonitoring = (item: any) => {
        navigate(`/dashboard/feeder/analysis/peak-monitoring?feeder_code=${item?.feeder_code && item?.feeder_code}&mg_id=${item?.feeder_mg_id && item?.feeder_mg_id}`)
        dispatch(setPeakMonitoringFeeder((item?.feeder_code && item?.feeder_code)))
        dispatch(setDate(_date))
        dispatch(fetchFeederPeakMonitoringAsync())
    }

    const loadFeederProjection = (item: any) => {
        let start_date = _date && getStartDateAndEndDateOfMonth(_date).firstDate
        let end_date = _date && getStartDateAndEndDateOfMonth(_date).lastDate

        navigate(`/dashboard/feeder/analysis/projection?feeder_code=${item?.feeder_code && item?.feeder_code}&mg_id=${item?.feeder_mg_id && item?.feeder_mg_id}`)
        dispatch(setProjectionFeeder((item?.feeder_code && item?.feeder_code)))
        dispatch(setProjectionStartDate(start_date))
        dispatch(setProjectionEndDate(end_date))
        dispatch(fetchFeederProjectionAsync())

    }






    return (
        <>
            <div className="">
                {data && Array.isArray(data) && data.length > 0 ?
                    <div className='grid grid-cols-3 justify-items-center gap-4 w-full'>
                        {
                            data && data?.map((value: any, index: number) => {
                                const cardBgColour: string = (() => {
                                    if (value?.current_status === "on_supply") {
                                        return "#69D475";
                                    } else if (value?.current_status === "no_data_sync") {
                                        return "#e84d49";
                                    } else if (value?.current_status === "not_on_supply") {
                                        return "#EE9C31";
                                    } else if (value?.current_status === "load_shedding") {
                                        return "#407AEE";
                                    } else {
                                        return "#9AA0A8";
                                    }
                                })();
                                
                                return (
                                    <div key={index} className="w-full">
                                        <Card className="bg-white shadow-md rounded-md overflow-hidden w-full">
                                            {/* Card content structure */}
                                            {/* <div className="p-2 bg-gray-400">
                                                <div className="flex justify-between items-center">
                                                    <div className=' whitespace-nowrap'>
                                                        <span className=''>
                                                            <span className="text-clip overflow-hidden">
    
                                                                {value?.feeder_name && value?.feeder_name} |{' '}
                                                            </span>
                                                            <span className="text-clip overflow-hidden">
                                                                {value?.feeder_code && value?.feeder_code}
    
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center whitespace-nowrap">
                                                        <Gauge />
                                                        <span className="ml-1"> - {value?.meter_no && value?.meter_no}</span>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="p-2 bg-gray-300">
                                                {/* Content inside the card */}
                                                <div className="flex justify-between items-center">
                                                    <div className="flex items-center space-x-1">
                                                        <span className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[250px] cursor-pointer" title={value?.feeder_name}>
                                                            {value?.feeder_name && value?.feeder_name || "-"}
                                                        </span>
                                                        {/* <span className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[110px] cursor-pointer" title={value?.feeder_code}>
                                                            {value?.feeder_code && `| ${value?.feeder_code}`}
                                                        </span> */}
                                                        <span className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[110px] cursor-pointer" title={value?.service_band}>
                                                            {value?.service_band || "-" && `| ${value?.service_band || "-"}`}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center cursor-pointer" title='Meter No'>
                                                        <Gauge />
                                                        <span className="ml-1">{value?.meter_no && value?.meter_no || "-"}</span>
                                                    </div>
                                                </div>
                                            </div>





                                            <div className="">
                                                {/* Other card content */}
                                                <div className='p-2' style={{ backgroundColor: cardBgColour }}>
                                                    <div className="flex justify-center items-center font-bold">Feeder Energy</div>
                                                    {/* <div className="space-y-4"> */}
                                                    <div className="flex justify-around">
                                                        <div className="flex flex-col justify-center ">
                                                            <div className="font-semibold text-center text-xs">Consumption </div>
                                                            <div className="w-[100px] h-[100px] rounded-full bg-white border flex flex-col justify-center items-center cursor-pointer" title={(value?.import_energy) ? String(value?.import_energy / 100) : "-"}>
                                                                <div className="font-semibold text-black">{value?.import_energy && value?.import_energy ? millify(value?.import_energy / 100) : "-"}</div>
                                                                <div className="font-semibold text-black text-sm">KWH</div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="flex flex-col justify-center">
                                                            <div className="font-semibold text-center text-xs">Output </div>
                                                            <div className="w-[100px] h-[100px] rounded-full bg-white border flex flex-col justify-center items-center cursor-pointer" title={(value?.export_energy) ? String(value?.export_energy / 100) : "-"}>
                                                                <div className="font-semibold text-black">{value?.export_energy && value?.export_energy ? millify(value?.export_energy / 100) : "-"}</div>
                                                                <div className="font-semibold text-black text-sm">KWH</div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className='p-2'>
                                                <TableComponent data={toTableData(value)} headers={["type", "voltage", "current"]} />
                                                <div className="relative py-1">
                                                    <div className="absolute inset-0 flex items-center">
                                                        <div className="w-full border-b border-gray-300"></div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        <span>Availability : {value?.availability && convertMinutesToHoursAndMinutes(value?.availability) || "-"}</span>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <span className="ml-1">Downtime : {value?.downtime && convertMinutesToHoursAndMinutes(value?.downtime) || "-"}</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        <span>Power Factor : {value?.power_factor && value?.power_factor.toFixed(2) || "-"}</span>
                                                    </div>

                                                    <div className="flex items-center">
                                                        {/* <button className="bg-transparent hover:text-blue-400 text-blue-700 font-semibold py-2 px-4 text-xs rounded">
                                                View More Details
                                            </button> */}
                                                        <MoreDetails data={value} />
                                                    </div>
                                                </div>

                                                <div className="relative py-1">
                                                    <div className="absolute inset-0 flex items-center">
                                                        <div className="w-full border-b border-gray-300"></div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='pt-0 p-2 space-y-1'>
                                                <div className="flex justify-center space-x-4">
                                                    <button className="bg-blue-200 hover:bg-blue-400 text-blue-700 font-semibold py-2 px-2 rounded" onClick={() => loadPim(value)}>
                                                        Live PIM
                                                    </button>
                                                    <button className="bg-blue-200 hover:bg-blue-400 text-blue-700 font-semibold py-2 px-2 rounded" onClick={() => loadLogSheet(value)}>
                                                        Log
                                                    </button>
                                                    <button className="bg-blue-200 hover:bg-blue-400 text-blue-700 font-semibold py-2 px-2 rounded" onClick={() => loadPeakMonitoring(value)}>
                                                        Peak Monitoring
                                                    </button>

                                                    <button className="bg-blue-200 hover:bg-blue-400 text-blue-700 font-semibold py-2 px-2 rounded" onClick={() =>
                                                        // window.open(`/more-charts/cards?feeder_code=${value?.feeder_code && value?.feeder_code}&_date=${state?.filter?._date && state?.filter?._date}&mg_id=${value?.feeder_mg_id && value?.feeder_mg_id}`, '_blank')
                                                        loadFeederProjection(value)
                                                    }>
                                                        Projection
                                                    </button>
                                                    {/* <MoreDetailsModal  /> */}
                                                </div>
                                            </div>
                                            <CardFooter className='bg-slate-100 p-1 border-2 border-transparent border-t-slate-200 flex justify-center'>
                                                <span className='flex flex-row-reverse text-xs cursor-pointer'>Last Reading at : {value?.last_reading_at && getFormatToDateTimeWithoutSecondsTogether(value?.last_reading_at) || '-'}</span>
                                            </CardFooter>
                                        </Card>
                                    </div>
                                )
                            }
                            )
                        }
                    </div> :
                    <>

                        {
                            isLoading && isLoading ? <UsersListLoading />
                                : <div className="bg-blue-100 text-blue-700 border border-blue-400 rounded-md px-4 py-3 flex justify-center items-center space-x-2" role="alert">
                                    <AlertCircle className='text-info ' /> <span>
                                        Please select appropriate filters
                                    </span>
                                </div>
                        }

                    </>

                }
            </div>

        </>
    );
}

const FeederCards: React.FC<Props> = ({ }) => {
    return <>
        {/* <QueryRequestProvider  >
            <QueryResponseProvider > */}
        <Main />
        {/* </QueryResponseProvider>
        </QueryRequestProvider> */}
    </>
}

export default FeederCards