import { UsersListLoading } from '../../../../../components/ui/UsersListLoading';
import React, { useMemo } from 'react'
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';
import { useQueryResponseData, useQueryResponseLoading } from '../Context';
// import { getFormatToDateTimeWithoutSecondsTogether } from '@/helper/index';
import { getFormatToTimeWithoutSeconds } from '../../../../../helpers';
import millify from 'millify';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getfeederPeakMonitoringReducerState } from 'src/redux/Reducers/feederPeakMonitoringReducer';



type Props = {}

const PeakMonitoringGraph = (props: Props) => {

    // const colors = ['#3e853e', '#71b871', '#94ca94', '#4da64d', '#1a87ff', '#4da2ff', '#007aff', '#0055b3'];
    // const isLoading = useQueryResponseLoading();
    // const apiResp: any = useQueryResponseData();

    const { tableData, isLoading } = useSelector((state: RootState) => getfeederPeakMonitoringReducerState(state))
    let graph_data: any = useMemo(() => tableData || [], [tableData]);
    console.log(graph_data);


    let x_data = graph_data?.x?.date_time;
    // && getFormatToDateTimeWithoutSecondsTogether(graph_data?.x?.date_time);

    let A_A = graph_data?.y?.A_A;
    let A_B = graph_data?.y?.A_B;
    let A_C = graph_data?.y?.A_A;
    let A_MAX = graph_data?.y?.A_MAX;
    let V_A = graph_data?.y?.V_A;
    let V_B = graph_data?.y?.V_B;
    let V_C = graph_data?.y?.V_C;
    let V_MAX = graph_data?.y?.V_MAX;
    let KWH_TOTAL = graph_data?.y?.KWH_TOTAL;




    const options: any = {
        // color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            // right: '20%'
        },

        legend: {
            data: ['A_A', 'A_B', 'A_C', 'V_A', 'V_B', 'V_C', 'A_MAX', 'V_MAX'],
            selected: {
                'A_A': false, 'A_B': false, 'A_C': false, 'V_A': false, 'V_B': false, 'V_C': false
            },
        },
        xAxis: [,
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    interval: 0,
                    rotate: 90,
                    formatter: function (value: any, index: any) {
                        return getFormatToTimeWithoutSeconds(value);
                    }
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Voltage',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'Current',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: 'V_MAX',
                type: 'line',
                yAxisIndex: 0,
                data: V_MAX,
                color: '#0055b3',
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'A_MAX',
                type: 'line',
                yAxisIndex: 1,
                data: A_MAX,
                color: '#3e853e',
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'A_A',
                type: 'line',
                yAxisIndex: 1,
                data: A_A,
                color: '#94ca94',
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'A_B',
                type: 'line',
                yAxisIndex: 1,
                data: A_B,
                color: '#71b871',
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'A_C',
                type: 'line',
                yAxisIndex: 1,
                data: A_C,
                color: '#4da64d',
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'V_A',
                type: 'line',
                yAxisIndex: 0,
                data: V_A,
                color: '#4da2ff',
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'V_B',
                type: 'line',
                yAxisIndex: 0,
                data: V_B,
                color: '#1a87ff',
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'V_C',
                type: 'line',
                yAxisIndex: 0,
                data: V_C,
                color: '#007aff',
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
        ]
    }


    return (
        <>
            {isLoading && <UsersListLoading key={'test-loader'} />}

            {/* <div 
            // style={{ width: '100%' }}
            >
                <ReactEchartsConfig option={options} loading={false} 
                style={{ width: '1700px', height: '550px' }} 
                />
            </div> */}
            <div className="flex justify-center items-center">
                <div>
                    <ReactEchartsConfig
                        option={options}
                        loading={false}
                        style={{ width: '1400px', height: '500px' }}
                    />
                </div>
            </div>
        </>
    )
}
export default PeakMonitoringGraph