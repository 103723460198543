
import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState, VisibilityState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { cn } from 'src/helpers';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { DataTablePagination } from './data-table-pagination';
// import { useQueryResponsePagination, useQueryResponseLoading } from '../core/QueryResponseProvider';
import { Loader2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { fetchAllCronJobListAsync, getCronJobFilterReducerState, setPaggination } from 'src/redux/Reducers/cronJobReducer';
import { getDailySummaryReducerState } from 'src/redux/Reducers/dailySummaryReducer';
import dayjs from 'dayjs';

type Props = {}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]

}

const MainTable = <TData, TValue>({ columns, data }: DataTableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([]);

  const { page, items_per_page, isLoading, total_records, total } = useSelector((state: RootState) => getDailySummaryReducerState(state))
  const dispatch = useDispatch()
  // const isLoading = useQueryResponseLoading()

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })


  useEffect(() => {
    dispatch(setPaggination({ pageIndex: pageIndex + 1, pageSize }))
    dispatch(fetchAllCronJobListAsync())
    return () => { }
  }, [pageIndex, pageSize])


  // console.log({ pageIndex, pageSize });


  const pagination = React.useMemo(
    () => ({
      pageIndex: page - 1,
      pageSize: items_per_page,
    }),
    [page, items_per_page]
  )

  // const paginations = React.useMemo(
  //   () => ({
  //     pageIndex: state.page - 1 ,
  //     pageSize: state.items_per_page,
  //   }),
  //   [state.page, state.items_per_page]
  // )

  const pageCounts = useMemo(() => total ? Number(total) : 1, [total])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    // enableRowSelection: true,
    pageCount: pageCounts,
    enableRowSelection: true,
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  const dt = dayjs(new Date()).format('DD MMMM YYYY')

  return (
    <>

      <div className='w-full  overflow-scroll static top-0 left-0 right-0'>
        <div className='w-full border border-[#ffff66] flex justify-center items-center text-xs bg-[#093163] h-10 text-white top-0 left-0 right-0 uppercase'>DAILY AND PAST 7 DAYS SUMMARY OF BAND A FEEDERS COMPLIANCE RECORDS AS OF {dt} </div>
        <ScrollArea className="  w-full h-[580px] relative rounded-md ">
          <Table className="w-full  rtl:text-right border border-[#ffff66]">
            <TableHeader className="w-full   sticky top-0  left-0 right-0 table-auto">
              {table.getHeaderGroups().map((headerGroup) => <TableRow key={`${headerGroup.id}`} className='w-full text-xs bg-[#093163] hover:bg-[#093163]'>

                {headerGroup.headers.map((header) => {
                  const columnRelativeDepth = header.depth - header.column.depth;

                  if (
                    !header.isPlaceholder &&
                    columnRelativeDepth > 1 &&
                    header.id === header.column.id

                  ) {
                    return null;
                  }

                  let rowSpan = 1;
                  if (header.isPlaceholder) {
                    const leafs = header.getLeafHeaders();
                    rowSpan = leafs[leafs.length - 1].depth - header.depth;
                  }
                  return (
                    <TableHead key={header.id}
                      colSpan={header.colSpan}
                      rowSpan={rowSpan}
                      className="text-center cursor-default text-white text-xs border border-[#ffff66] font-medium" scope='column'>
                      {header.isPlaceholder ? null :
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}


              </TableRow>)}

            </TableHeader>

            <TableBody
              className="w-full">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    // onClick={() =>handleClick(row.original)}
                    className='w-full odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 hover:bg-gray-200' >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className="text-center font-medium text-xs overflow-clip border border-[#ffff66]" scope="row" >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="w-full h-24 text-center ">
                    {isLoading ? <div className='w-full flex justify-center items-center '>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
                    </div> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>

      </div>
      <div className='mt-2 w-full'>
        {/* <DataTablePagination table={table} /> */}
      </div>
    </>

  )
}

export default MainTable


