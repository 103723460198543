import { dropdownOptions } from 'src/helpers';
import { Button } from '../../../../../components/ui/button';
import React, { useState } from 'react'
import DistrictFilter from 'src/Modules/Filters/Districts';
import SubStationFilter from 'src/Modules/Filters/Substation';
import DateInput from 'src/components/commons/DateInput';
import { SelectDropdown } from 'src/components/commons/DropdownSelect';
import { format } from 'date-fns';
import { FeederCardsMainState } from '../Context';
import TransformerFilter from 'src/Modules/Filters/Transformer';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterReducerState, setDate, setDistrict, setSubstation, setTransformer, setType } from 'src/redux/Reducers/filterReducer';
import { RootState } from 'src/redux/store';
import { fetchFeederCardsAsync } from 'src/redux/Reducers/feederCardsReducer';

type Props = {}

const FeederCardsFilter = (props: Props) => {

    
    const { state, updateState } = FeederCardsMainState()
    const dispatch = useDispatch()
    const { district_id, filterType, tcn_substation_id, tcn_transformer_id,_date } = useSelector((state: RootState) => getFilterReducerState(state))
    // console.log({_rootState});

    const [filterData, setfilterData] = useState<any>({
        district_id: "",
        tcn_substation_id: "",
        tcn_transformer_id: "",
        _date: ""
    })

    const handleSelect = (selectedValue: string) => {
        // Handle the selected value
        // console.log('Selected value:', selectedValue);
        dispatch(setType(selectedValue))
    };

    const handleSubmit = () => {
        let newFilter = { ...state?.filter, ...filterData }
        updateState({ ...state, filter: newFilter })
    }

    return (
        <>
            <div className=''>
                <SelectDropdown options={dropdownOptions} onSelect={handleSelect} value={filterType || filterType} />
            </div>
            {filterType && filterType == "dist" ?
                <DistrictFilter value={district_id} onChange={(e: any) => {
                    dispatch(setDistrict(e));
                    dispatch(setSubstation(""))
                    dispatch(setTransformer(""))
                }} /> : <></>}
            {filterType && filterType == "substn" ? <>
                <SubStationFilter value={tcn_substation_id} onChange={(e: any) => {
                    dispatch(setSubstation(e))
                    dispatch(setDistrict(""))
                    dispatch(setTransformer(""))
                    // updateState({ ...state, filter: { ...state?.filter, substation_id: e } })
                    // refetch()
                }} />
                <TransformerFilter substation_id={tcn_substation_id} value={tcn_transformer_id} onChange={(e: any) => {
                    dispatch(setTransformer(e))
                    dispatch(setDistrict(""))
                    // updateState({ ...state, filter: { ...state?.filter, substation_id: e } })
                    // refetch()
                }} />
            </>
                : <></>}
            <DateInput date={_date} selectedDate={(e: any) => { dispatch(setDate(e && format(new Date(e), "yyyy-MM-dd"))) }} />
            <Button className=' bg-teal-500  text-white border-white hover:border-transparent hover:text-white hover:bg-teal-300 font-bold' onClick={() => {
                dispatch(fetchFeederCardsAsync())
            }
            }>Submit</Button>
        </>
    )
}

export default FeederCardsFilter