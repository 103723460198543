/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
  ERROR_CODES,
  PaginationState,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  print,
  stringifyObjectToRequestQuery,
  stringifyRequestQuery
} from 'src/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { getSubStationCardsApiData } from './_request'
import { useLocation } from 'react-router-dom'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children, props, type, postdata }) => {

  const { state, updateState } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [interval, setinterval] = useState(60)


  const { search } = useLocation();

  useEffect(() => {

    const params = search && search?.split('?')[1].split("&");
    // console.log({ params });
    let searchParams: any = {};

    params && params.forEach((o: any) => {
      let splitParam = o?.split("=");
      searchParams[splitParam[0]] = splitParam[1];
    });

    if (!searchParams['_date']) {
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date in 'YYYY-MM-DD' format
      searchParams['_date'] = currentDate;
    }

    // console.log({ MainContextSearchParams: searchParams });

    updateState({
      ...state,
      filter: {
        ...searchParams
      }
    })

    return () => { }

  }, [search])


  console.log(state, interval);


  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery])


  useEffect(() => {
    setinterval(Number(state?.other?.rr || state?.filter?.rr))
    return () => { }
  }, [state?.other?.rr, state?.filter?.rr])


  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `FEEDER-ENERGY-SUBSTATION-CARDS-${query}`,
    async () => {

      const newstate = state?.filter && JSON.parse(JSON.stringify(state?.filter));
      // console.log({ newstate });
      // // delete newstate["_fkey"];
      delete newstate["items_per_page"];
      delete newstate["page"];
      delete newstate["rr"]
      let table_query = { ...newstate }
      // print(newstate)
      const queryString = stringifyObjectToRequestQuery(table_query);
      console.log(queryString);
      // if (state?.filter?.tcn_substation_id && state?.filter?.tcn_substation_id)
      return getSubStationCardsApiData(queryString);
    },
    {
      cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: (interval * 1000),
      // refetchOnReconnect: false,
      // retry: false,
      //  staleTime: 5000,
    }

  )

  let responseData: any = useMemo(() => response, [response])

  let isError = useMemo(() => ERROR_CODES.includes(responseData?.code) ? true : false, [responseData])
  let message = useMemo(() => ERROR_CODES.includes(responseData?.code) ? responseData?.message : '', [responseData])

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query, isError, message }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()

  if (!response || !response?.payload || !response?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.payload?.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination
}

