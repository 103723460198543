import React, { useEffect, useMemo, useState } from 'react';
import { format, getYear, setYear, setMonth } from 'date-fns';
import { Button } from 'src/components/ui/button';
import { cn } from 'src/helpers/index';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';

export function MonthPicker({ onChange, value }: { onChange: (date: string) => void, value: string }) {

    const yearArray = useMemo(() => [...Array(2050)].map((_, i) => 2000 + i * 1), [])

    const [month, year] = useMemo(() => {
        return value && value.split("-") || [format(setMonth(new Date(), new Date().getMonth()), 'MMMM').slice(0, 3), new Date().getFullYear()]
    }, [value])

    console.log({ month, year, value })

    const shortYear = useMemo(() => year.toString().slice(-2), [year])

    return (
        <div className="my-2">
            <div className="p-1">
                <Select value={"20" + year.toString()} onValueChange={(value: any) => onChange(`${month}-${value.toString().slice(-2)}`)}>
                    <SelectTrigger className="w-full" defaultValue={year}>
                        <SelectValue placeholder={year} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {yearArray && yearArray.map((cur, index) => (
                                <SelectItem value={`${cur}`} key={`${cur}-${index}`} className='text-sm font-medium text-gray-800'>
                                    {`${cur}`}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <div className="grid grid-cols-4 gap-1 mt-1">
                    {[...new Array(12)].map((o: any, indx) => format(setMonth(new Date(), indx ), 'MMMM').slice(0, 3)).map((m, indx) => (
                        <div
                            key={`month-${indx}`}
                            className={cn("p-2 text-center cursor-pointer text-gray-800 text-xs font-medium rounded-lg", { "bg-gray-300": m == month })}
                            onClick={() => {
                                onChange(`${m}-${shortYear}`)
                            }}
                        >
                            {m}
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}

interface DateMonthFilterProps {
    setMonthFilter: (formattedDate: string) => void;
    monthYear?: string;
}

function DateBymFilter({ setMonthFilter, monthYear }: DateMonthFilterProps) {

    // const [selectedMonth, setSelectedMonth] = React.useState<Date | undefined>(undefined);
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const lastTwoDigitsOfYear = year.toString().slice(-2);
    // const currentMonth = currentDate.toLocaleString('default', { month: 'short' });

    // const [date, setDate] = useState<string>(`${currentMonth}-${lastTwoDigitsOfYear}`);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    // const dispatch=useDispatch()
    //   useEffect(() => {
    //     setDate(monthYear ? monthYear : `${currentMonth}-${lastTwoDigitsOfYear}`);
    //   }, [monthYear, currentMonth, lastTwoDigitsOfYear]);

    // const handleMonthChange = (date: Date) => {
    //     setSelectedMonth(date);
    // };

    // const formattedDate = selectedMonth ? format(selectedMonth, 'MMM-yy').toUpperCase() : date.toUpperCase();


    //   React.useEffect(() => {
    //     setMonthFilter(formattedDate);
    //     // dispatch(updateDatefilter(formattedDate))
    //     if (selectedMonth) {
    //       setIsOpen(false);
    //     }
    //   }, [formattedDate, selectedMonth, setMonthFilter]);

    return (
        <Popover open={isOpen} onOpenChange={(open: boolean) => setIsOpen(open)}>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    className={cn('w-1/6 justify-center text-left font-normal my-2 mr-10')}
                >
                    <span className="font-medium text-black">{monthYear}</span>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <MonthPicker onChange={(e: any) => {
                    console.log(e)
                    setMonthFilter(e)
                }} value={monthYear || ""} />
            </PopoverContent>
        </Popover>
    );
}

export default DateBymFilter;
