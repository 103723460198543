import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { Skeleton } from './ui/skeleton';
import { ScrollArea, ScrollBar } from './ui/scroll-area';
import { cn } from '../helpers';


type Props = {
    headers: string[];
    data?: any[];
    showSRNO?: boolean;
    isLoading?: boolean;
    tableClasses?: any[]
};

const TableComponent3 = ({ headers, data, showSRNO = false, isLoading, tableClasses }: Props) => {

    console.log({ tableClasses, headers })

    return (
        <div className='h-[600px] overflow-auto '>
            <Table className="table-auto">
                <TableHeader >
                    <TableRow className="py-2 ">
                        {headers &&
                            headers.map((headerName: string, index: number) => (
                                <TableHead
                                    key={index}
                                    className={cn(`uppercase font-semibold text-gray-500 text-xs bg-secondary h-8 text-center whitespace-nowrap w-fit `, { " sticky left-0  ": headerName == "time/date" })}
                                >
                                    {headerName}
                                </TableHead>
                            ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {/* <ScrollArea className="h-full rounded-md border p-4"> */}
                    {isLoading ? (
                        [...Array(10)].map((_, indx: number) => (
                            <TableRow key={indx}>
                                {/* {showSRNO && <TableCell key={`srno-${indx}`}><Skeleton className="w-[30px] h-[8px]" /></TableCell>} */}
                                {headers &&
                                    headers.map((header: string, indx2: number) => (
                                        <TableCell key={`cell-${indx}-${indx2}`}><Skeleton className={cn("w-[30px] h-[8px] w-fit", { "sticky left-0 ": header == "time/date" })} /></TableCell>
                                    ))}
                            </TableRow>
                        ))
                    ) : (
                        <>
                            {data && data.length > 0 ? (
                                data.map((d: any, indx: number) => {
                                    const d_values = d && Object.values(d);

                                    return (
                                        <TableRow key={`data-row-${indx}`}>
                                            {/* {showSRNO && <TableCell key={`srno-${indx}`}>{indx + 1}</TableCell>} */}
                                            {headers &&
                                                headers.map((header, indx2: number) => {
                                                    if (typeof d_values[indx2] === 'string' || typeof d_values[indx2] === 'number') {
                                                        let classes: any = "";
                                                        const classnames = tableClasses && tableClasses[header as keyof Object]
                                                        if (classnames && d_values[indx2]) {
                                                            classes = classnames && Object.entries(classnames).find(([key, value]) => Number(value) == Number(d_values[indx2]))
                                                            classes = classes && classes[0]?.toString() || "";
                                                        }
                                                        if(classes) console.log(classes)
                                                        return <TableCell key={`cell-${indx}-${indx2}`} className={cn("w-fit "+classes, { "sticky left-0 bg-secondary": header == "time/date"})} >{d_values[indx2]}</TableCell>;
                                                    }
                                                    return null;
                                                })}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell className="text-center" colSpan={headers.length}>No Records</TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                    {/* <ScrollBar orientation="horizontal" /> */}
                    {/* </ScrollArea> */}
                </TableBody>
            </Table>
        </div>
    );
};

export default TableComponent3;
