
import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState, VisibilityState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { cn } from 'src/helpers';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { DataTablePagination } from './data-table-pagination';
import { useQueryResponsePagination, useQueryResponseLoading } from '../core/QueryResponseProvider';
import { Loader2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { fetchAllCronJobListAsync, getCronJobFilterReducerState, setPaggination } from 'src/redux/Reducers/cronJobReducer';

type Props = {}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

const MainTable = <TData, TValue>({ columns, data }: DataTableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([]);

  const { page, items_per_page, isLoading, total_records, total } = useSelector((state: RootState) => getCronJobFilterReducerState(state))
  const dispatch = useDispatch()
  // const isLoading = useQueryResponseLoading()

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })


  useEffect(() => {
    dispatch(setPaggination({ pageIndex: pageIndex + 1, pageSize }))
    dispatch(fetchAllCronJobListAsync())
    return () => { }
  }, [pageIndex, pageSize])


  // console.log({ pageIndex, pageSize });


  const pagination = React.useMemo(
    () => ({
      pageIndex: page - 1,
      pageSize: items_per_page,
    }),
    [page, items_per_page]
  )

  // const paginations = React.useMemo(
  //   () => ({
  //     pageIndex: state.page - 1 ,
  //     pageSize: state.items_per_page,
  //   }),
  //   [state.page, state.items_per_page]
  // )

  const pageCounts = useMemo(() => total ? Number(total) : 1, [total])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    // enableRowSelection: true,
    pageCount: pageCounts,
    enableRowSelection: true,
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })


  return (
    <>
      <div className=" w-full relative overflow-x-auto  mt-4 sm:rounded-lg px-4">
        {data ? (
          <ScrollArea className="w-full h-[495px] relative rounded-md ">
            <Table className="w-full text-sm  text-left rtl:text-right text-gray-900 dark:text-gray-400 relative border">
              <TableHeader className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center hover:bg-gray-400 sticky top-0 left-0 right-0">
                {table.getHeaderGroups().map((headerGroup) => <TableRow key={`${headerGroup.id}`}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="text-center cursor-default">
                        {header.isPlaceholder ? null :
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>)}

              </TableHeader>

              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (

                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className='odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 hover:bg-gray-200'

                    >
                      {row.getVisibleCells().map((cell: any) => {

                        return (
                          <TableCell key={cell.id} className=" overflow-clip">
                            {

                              (
                                flexRender(cell.column.columnDef.cell, cell.getContext())
                              )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      {isLoading ? <div className='flex justify-center items-center '>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
                      </div> : "No results."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ScrollArea>) : (<div></div>)}
        <div className='mt-2'>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  )
}

export default MainTable



