import { dropdownOptions } from 'src/helpers';
import { Button } from '../../../../../components/ui/button';
import React, { useState } from 'react'
import DistrictFilter from 'src/Modules/Filters/Districts';
import SubStationFilter from 'src/Modules/Filters/Substation';
import DateInput from 'src/components/commons/DateInput';
import { SelectDropdown } from 'src/components/commons/DropdownSelect';
import { format } from 'date-fns';
import { FeederEnergyTripDetailsState } from '../Context';
import TransformerFilter from 'src/Modules/Filters/Transformer';

type Props = {}

const TripStatusFilter = (props: Props) => {

    const [filterType, setfilterType] = useState<any>("")
    const { state, updateState } = FeederEnergyTripDetailsState()
    const [filterData, setfilterData] = useState<any>({
        district_id: "",
        tcn_substation_id: "",
        tcn_transformer_id: "",
        _date: ""
    })

    const handleSelect = (selectedValue: string) => {
        // Handle the selected value
        // console.log('Selected value:', selectedValue);
        setfilterType(selectedValue)
    };

    const handleSubmit = () => {
        let newFilter = { ...state?.filter, ...filterData }
        updateState({ ...state, filter: newFilter })
    }

    return (
        <>
            <div className=''>
                <SelectDropdown options={dropdownOptions} onSelect={handleSelect} value={filterType} />
            </div>
            {filterType && filterType == "dist" ?
                <DistrictFilter onChange={(e: any) => {
                    setfilterData({ ...filterData, district_id: e, tcn_substation_id: "", tcn_transformer_id:"" })
                }} /> : <></>}
            {filterType && filterType == "substn" ? <>
                <SubStationFilter onChange={(e: any) => {
                    setfilterData({ ...filterData, tcn_substation_id: e, district_id: "" })
                }} />
                <TransformerFilter substation_id={filterData?.tcn_substation_id || 0} onChange={(e: any) => {
                    setfilterData({ ...filterData, tcn_transformer_id: e, district_id: "" })
                }} /></>
                : <></>}
            <DateInput date={filterData?._date} selectedDate={(e: any) => {
                setfilterData({ ...filterData,  _date: e && format(new Date(e), "yyyy-MM-dd") } )
            }}
            />
            <Button className=' bg-teal-500  text-white border-white hover:border-transparent hover:text-white hover:bg-teal-300 font-bold' onClick={(e: any) => {
                handleSubmit()
            }
            }>Submit</Button>
        </>
    )
}

export default TripStatusFilter