import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { stringifyObjectToRequestQuery } from 'src/helpers';
import axios, { AxiosError } from 'axios';
import { logSheetApiData } from 'src/Modules/TestComponent/Component/LogDetails/Context/core/_request';

interface IFeederLogTableReducerState {
    isLoading: boolean,
    error: boolean,
    message: string | undefined,
    tableData: any[],
    status: undefined | number,
    feeder_code: string,
    start_date: string,
    end_date: string
}


export const initaiState: IFeederLogTableReducerState = {
    isLoading: false,
    error: false,
    message: undefined,
    tableData: [],
    status: undefined,
    feeder_code: "",
    start_date: "",
    end_date: ""
}

export const fetchFeederLogTableAsync: any = createAsyncThunk(
    'FeederLogTableReducer/fetchAll',
    async (_, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().feederLogTableReducerState
            console.log(getFilterState);

            let query = {
                feeder_code: getFilterState?.feeder_code,
                start_date: getFilterState?.start_date,
                end_date: getFilterState?.end_date
            }

            const queryString = stringifyObjectToRequestQuery(query);
            console.log(queryString)
            const response: any = await logSheetApiData(queryString);

            console.log({ response })

            if (response.code === 200 && response.data) {
                return response; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = "";//response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const feederLogTableReducer = createSlice({
    name: 'feederLogTableReducer',
    initialState: initaiState,
    reducers: {


        setFeeder: (state, action: PayloadAction<any>) => {
            state.feeder_code = action.payload;
        },
        setStartDate: (state, action: PayloadAction<any>) => {
            state.start_date = action.payload;
        },
        setEndDate: (state, action: PayloadAction<any>) => {
            state.end_date = action.payload;
        },

        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload?.data?.message;
            state.status = action.payload?.status;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        reseteState: (state) => {
            state = initaiState
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeederLogTableAsync.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.tableData = payload.data;
                state.error = false;
                state.message = undefined
            })
            .addCase(fetchFeederLogTableAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.tableData = []
                state.isLoading = true
            })
            .addCase(fetchFeederLogTableAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })
    }
});

export const { resetError, setError, reseteState, setFeeder, setStartDate, setEndDate } = feederLogTableReducer.actions;

export const getfeederLogTableReducerState = (state: RootState) => state.feederLogTableReducerState;

export default feederLogTableReducer.reducer;