import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from './redux/store';
import { getFilterReducerState } from './redux/Reducers/filterReducer';
import { AuthInit } from './Modules/Auth/Core';

function App() {


  const state = useSelector((state: RootState) => getFilterReducerState(state))

  // console.log({ state })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
      <AuthInit >
        <QueryClientProvider client={queryClient}>
          <Outlet />
        </QueryClientProvider>
      </AuthInit>
    </>
  );
}

export default App;
