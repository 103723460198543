import { useEffect, useMemo, useState } from 'react';
import { FeederCardsMainState, QueryRequestProvider, QueryResponseProvider } from './Context';
import FeederCards from './Components/FeederCards';
import FeederCardsFilter from './Components/FeederCardsFilter';


type Props = {}

const Main = (props: Props) => {
    const { state, updateState } = FeederCardsMainState();


    return <>
        <div className="flex justify-between items-center ">
            <div className='justify-self-start text-center font-bold'>All Feeders</div>
            <div className='flex justify-end space-x-2 items-center'>
                <FeederCardsFilter />
            </div>
        </div>
        <div className='p-2'>
            {/* <div className='text-center font-bold font-md'>Feeder Trips</div> */}
            <FeederCards />
        </div>
    </>
}

const FeederCardsMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default FeederCardsMain