import React, { lazy } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import LoadIntervalSummaryMain from 'src/Modules/TestComponent/Component/CronJob/Summary/LoadIntervalSummary/LoadIntervalSummaryMain'
import SummaryMain from 'src/Modules/TestComponent/Component/CronJob/Summary/SummaryMain'
import LoadIntervalSummaryDetailsMain from 'src/Modules/TestComponent/Component/CronJob/SummaryDetails/LoadIntervalSummaryDetails/LoadIntervalSummaryDetailsMain'
import SummaryDetailsMain from 'src/Modules/TestComponent/Component/CronJob/SummaryDetails/SummaryDetailsMain'
import DailyFeederTripsMain from 'src/Modules/TestComponent/Component/DailyFeederTrips/DailyFeederTripsMain'
import DailySummaryMain from 'src/Modules/TestComponent/Component/DailySummary/DailySummaryMain'
import DistrictWisePimMain from 'src/Modules/TestComponent/Component/DistrictWisePimAnalysis/DistrictWisePimMain'
import FeederCardsMain from 'src/Modules/TestComponent/Component/FeederCards/FeederCardsMain'
import FeederProjectionMain from 'src/Modules/TestComponent/Component/FeederProjection/FeederProjectionMain'
import GISDashboardMain from 'src/Modules/TestComponent/Component/GIS/Components/GISMain'
import LivePimMain from 'src/Modules/TestComponent/Component/LivePIM/LivePimMain'
import LogDetailsTable from 'src/Modules/TestComponent/Component/LogDetails/Components/LogDetailsTable'
import MoreChartsDashboardMain from 'src/Modules/TestComponent/Component/MoreCharts/MoreChartsDashboardMain'
import NetworkMapMain from 'src/Modules/TestComponent/Component/NetworkMapping/Components/NetworkMapMain'
import NetworkMapMainNew from 'src/Modules/TestComponent/Component/NetworkMapping/Components/NetworkMapMainNew'
import PeakMonitoringMain from 'src/Modules/TestComponent/Component/PeakMonitoring/PeakMonitoringMain'
import SubstationCards from 'src/Modules/TestComponent/Component/SubstationCards/Components/SubstationCards'
import SubstationCardsMain from 'src/Modules/TestComponent/Component/SubstationCards/SubstationCardsMain'
import SubstationWisePimMain from 'src/Modules/TestComponent/Component/SubstationPimAnalysis/SubstationWisePimMain'
import TripStatusMain from 'src/Modules/TestComponent/Component/TripStatus/TripStatusMain'
import TestcomponentMain from 'src/Modules/TestComponent/TestcomponentMain'
import HeaderComponent from 'src/components/HeaderComponent'
import TabsFilterComponent from 'src/components/TabsFilterComponent'


const FederRoutePages = () => {
  return <Routes>
    <Route element={<><Outlet /></>}>
      <Route path="analysis" element={<FeederCardsMain />} />
      <Route path="analysis/live-pim" element={<LivePimMain />} />
      <Route path="analysis/peak-monitoring" element={<PeakMonitoringMain />} />
      <Route path="analysis/log-sheet" element={<LogDetailsTable />} />
      <Route path="analysis/projection" element={<FeederProjectionMain />} />
      {/* <Route index element={<Navigate to={'dashboard/feeder/analysis'} />} /> */}
      <Route path={"*"} element={<>error</>} />
    </Route>
  </Routes>
}

const MoreChartsRoutePage = () => (
  <Routes>
    <Route element={<><Outlet /></>}>
      <Route path="dash" element={<MoreChartsDashboardMain />} />
      <Route path="dash/district-wise-pim" element={<DistrictWisePimMain />} />
      <Route path="dash/daliy-district-wise-trips" element={<DailyFeederTripsMain />} />
      <Route path="dash/substation-wise-pim" element={<SubstationWisePimMain />} />
      {/* <Route path="daily-Summary" element={<DailySummaryMain />} /> */}
      {/* <Route index element={<Navigate to='/more-charts' />} /> */}
      <Route path={"*"} element={<>error</>} />
    </Route>
  </Routes>
)

const DashboardsRoutePage = () => (
  <Routes>
    <Route element={<><Outlet /></>}>
      <Route path="feeder/*" element={<FederRoutePages />} />
      <Route path="gis" element={<GISDashboardMain />} />
      <Route path="net-map/edit" element={<NetworkMapMain />} />
      <Route path="net-map" element={<NetworkMapMainNew />} />
      <Route path="trip-status" element={<TripStatusMain />} />
      {/* <Route index element={<Navigate to={'dashboard/feeder/'} />} /> */}
      <Route path={"*"} element={<>error</>} />
    </Route>
  </Routes>
)

const CronRoutePage = () => (
  <Routes>
    <Route element={<><Outlet /></>}>
      <Route path="meter-reading" element={<SummaryMain />} />
      <Route path="meter-reading/details" element={<SummaryDetailsMain />} />
      <Route path="load-interval" element={<LoadIntervalSummaryMain />} />
      <Route path="load-interval/details" element={<LoadIntervalSummaryDetailsMain />} />

      <Route path={"*"} element={<>error</>} />
    </Route>


  </Routes>
)


const AppRoutes = () => {
  // console.log("app route")

  const MoreChartsPages = lazy(() => import('../Modules/TestComponent/Component/MoreCharts/RoutePage'))

  return (
    <Routes>
      <Route element={<HeaderComponent />}>
        <Route path="/more-charts/cards/*" element={<MoreChartsRoutePage />} />
        <Route path="/dashboard/*" element={<DashboardsRoutePage />} />
        <Route path="/cron/*" element={<CronRoutePage />} />
        <Route path="/ss-cards" element={<SubstationCardsMain />} />
        <Route path={"*"} element={<>error</>} />
      </Route>
      <Route path="/more-charts/cards/daily-Summary" element={<DailySummaryMain />} />
      {/* <Route >
        <Route path="more-charts/*" element={<MoreChartsRoutePage />} />
      </Route> */}
    </Routes>
  )
}

export default AppRoutes

