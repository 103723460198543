import axios, { AxiosResponse } from 'axios'
import { URL_DISRICT_PIM_GRAPH } from 'src/apis'


export const districtPimApiData = (query: string): Promise<any> => {
  return axios
    .get(`${URL_DISRICT_PIM_GRAPH}?${query}`)
    .then(async (d: any) => d?.data)
    .catch((e: any) => e?.response?.data)
}







