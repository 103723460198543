import React from 'react'
import SummaryFilters from './Components/SummaryFilters'
import { Card } from 'src/components/ui/card'
import SummaryTable from './Components/SummaryTable'
import { QueryRequestProvider, QueryResponseProvider } from './Context'
import { useAuth } from 'src/Modules/Auth/Core'

type Props = {}

const Main = (props: Props) => {

    // const { currentUser } = useAuth()
    // console.log(currentUser)

    return <>

        <div className='p-2 space-y-2'>
            <div className="flex justify-between items-center ">
                <div className='justify-self-start text-center font-bold'>Meter Reading Summary</div>
                <div className='flex justify-end space-x-2 items-center'>
                    <SummaryFilters />
                </div>
            </div>
            <Card className='space-y-2 p-2'>
                <SummaryTable />
            </Card>
        </div>
    </>
}

const SummaryMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default SummaryMain
