import React, { useEffect, useMemo } from 'react'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import FormikSelectPicker2 from '../../../components/ui/SelectPicker/FormikSelectPicker2'
import { prepareFilterOptions } from 'src/helpers'
import { useDispatch } from 'react-redux'

type Props = {
    onChange: (data: any) => void,
    isDefault?: boolean,
    feeder_id?: string,
    district_id?: string,
    servicecenter_id?: string,
    transfomer_id?: string,
    value?: string,
    isDisabled?: boolean
}


const Main: React.FC<Props> = ({ onChange, isDefault = false, feeder_id, district_id, servicecenter_id, transfomer_id, value, isDisabled }) => {

    const apiResp = useQueryResponseData();
    const isLoading = useQueryResponseLoading();
    const { response, refetch } = useQueryResponse();
    const data = useMemo(() => prepareFilterOptions(apiResp), [apiResp])
    const { state, updateState } = useQueryRequest();

    useEffect(() => {

        let filter: any = {};
        if (district_id) filter = { ...filter, 'dist_id': district_id };
        // if (servicecenter_id) filter = { ...filter, 'sc_id': servicecenter_id };
        // if (feeder_id) filter = { ...filter, 'fd_id': feeder_id };
        // if (transfomer_id) filter = { ...filter, 'dss_id': transfomer_id };

        updateState({
            filter
        })
        onChange(undefined)
        return () => { }
    }, [district_id])

    console.log({isLoading});
    


    return (
        // QueryReqpose / reques

        <FormikSelectPicker2
            placeholder='Select District'
            options={data}
            isLoading={isLoading}
            value={value}
            onchange={(e: any) => onChange(e)}
            // label={"Select District"}
            searchable
        />
    )
}

const DistrictFilter: React.FC<Props> = (props) => {

    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main {...props} />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default DistrictFilter