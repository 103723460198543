import { format } from 'date-fns'
import { Button } from '../../../../../components/ui/button'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DateInput from 'src/components/commons/DateInput'
import { fetchFeederPeakMonitoringAsync, getfeederPeakMonitoringReducerState, setDate } from 'src/redux/Reducers/feederPeakMonitoringReducer'
import { RootState } from 'src/redux/store'

type Props = {}

const PeakMonitoringFilters = (props: Props) => {

    const { _date } = useSelector((state: RootState) => getfeederPeakMonitoringReducerState(state))
    const dispatch = useDispatch()

    const submitHandler = () => {
        dispatch(fetchFeederPeakMonitoringAsync())
    }

    return (
        <>
            <div className='flex space-x-2'>
                <DateInput date={_date} selectedDate={(e: any) => { dispatch(setDate(e && format(new Date(e), "yyyy-MM-dd"))) }} />
                <Button onClick={submitHandler} >Submit</Button>
            </div>
        </>
    )
}

export default PeakMonitoringFilters