import { BadgeAlert, BadgeCheck, Phone, XCircle } from 'lucide-react'
import React from 'react'

type Props = {
    rowData?: any
}

const StatusDetails = ({ rowData }: any) => {
    // console.log(rowData);
    let content;

    if (rowData?.sync_status && rowData?.sync_status) {
        content = (
            <div className='text-green-600 text-center cursor-pointer' data-bs-toggle='tooltip' title='Approved'>
               <BadgeCheck size={20} />
            </div>
        );
    } 
    // else if (rowData?.status && rowData?.status == "IN_REVIEW") {
    //     content = (
    //         <div className='text-amber-600  cursor-pointer' data-bs-toggle='tooltip' title='In Review'>
    //             <BadgeAlert size={19} />
    //         </div>
    //     );
    // } 
    else {
        content = (<div className='text-red-400 text-center cursor-pointer' data-bs-toggle='tooltip' title='Rejected'><XCircle size={20} /></div>);
    }

    return (
        <>
        <div className='flex justify-center'>
            {content}
        </div>
        </>
    );
}

export default StatusDetails