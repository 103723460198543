import axios, { AxiosResponse } from 'axios'
import { URL_TCN_SUBSTATION_FILTER } from 'src/apis'

export const fetchSubstations = (query: string): Promise<any> => {
  return axios
    .get(`${URL_TCN_SUBSTATION_FILTER}?${query}`)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response)
}








