import * as React from "react"

import { Card, CardContent } from "../../../../../components/ui/card"
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "../../../../../components/ui/carousel"
import { Separator } from "../../../../../components/ui/separator"
import { useQueryResponseData, useQueryResponseLoading } from "../Context"
import Autoplay from "embla-carousel-autoplay"
import { Skeleton } from "src/components/ui/skeleton"

type Props = {}



const SubstationCards = (props: Props) => {

    const apiResp: any = useQueryResponseData();
    const isLoading = useQueryResponseLoading();
    const newApiResp = React.useMemo(() => apiResp || [], [apiResp])

    const newData = React.useMemo(() => {
        const totalSlides = newApiResp?.length / 12;
        // console.log(totalSlides)
        return [...new Array(Math?.ceil(totalSlides))].map((_, indx: number) => {
            let start = indx * 12;
            let end = start + 12;
            console.log({ start, end });
            return [...newApiResp?.slice(start, end)]
        })

    }, [newApiResp])

    // console.log({ newData, apiResp, isLoading });

    const plugin = React.useRef(Autoplay({ delay: 10000, stopOnInteraction: true }))


    return (
        <>

            {
                isLoading ? <>
                    {
                        <div className="grid grid-cols-4 gap-4 w-100">
                            {
                                [...new Array(12)].map((_, indx: number) => {
                                    return <div>
                                        <Skeleton className="w-100 h-[120px] rounded-lg" />
                                    </div>
                                })
                            }

                        </div>
                    }
                </> : <>
                    <Carousel
                        plugins={plugin.current ? [plugin.current] : []}
                        opts={{
                            align: "start",
                            loop: true
                        }}

                        className="w-full ">
                        <CarouselContent>
                            {
                                newData && newData?.length > 0 && newData?.map((data: any, indx: number) => {
                                    return <CarouselItem key={`CarouselItem-${indx + 1}`} >
                                        <div className="p-1">
                                            <div className="grid grid-cols-4 gap-4">
                                                {
                                                    data && Array.isArray(data) && data?.length > 0 && data?.map((cardData: any, iindx: number) => {
                                                        // console.log(cardData)

                                                        return <div key={`CarouseData-${indx}-${iindx}`} className="p-4">
                                                            <Card className=" shadow-xl border-gray-300 border-2 w-full">
                                                                <CardContent className="p-3">
                                                                    <div className="font-bold" title="Substation Name">
                                                                        {cardData && cardData?.tcn_substation_name || "-"}
                                                                    </div>
                                                                    <div className="font-bold text-xs text-gray-400 mb-2" title="Substation Code">
                                                                        {cardData && cardData?.tcn_substation_code || "-"}
                                                                    </div>
                                                                    <Separator />
                                                                    <div className="grid grid-cols-3">
                                                                        <div>
                                                                            <div className="text-xs font-semibold text-teal-400" title="Substation Total Count">Total Feeders</div>
                                                                            <div className="font-bold text-center text-lg">{cardData && cardData?.total_src_feeders_count || "-"}</div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="text-xs font-semibold text-teal-400" title="Substation Active Count">On Supply</div>
                                                                            <div className="font-bold text-center text-lg text-[#41bf50]">{cardData && cardData?.total_src_feeders_supply_count || "-"}</div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="text-xs font-semibold text-teal-400" title="Substation In-Active Count">Not On Supply</div>
                                                                            <div className="font-bold text-center text-lg text-[#ffa733db]">{cardData && cardData?.total_src_feeders_not_supply_count || "-"}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="grid grid-cols-3">
                                                                        <div>
                                                                            <div className="text-xs font-semibold text-teal-400" title="Substation Total Count">Load Shedding</div>
                                                                            <div className="font-bold text-center text-lg text-[#3946d5]">{cardData && cardData?.total_src_feeders_load_shedding_count || "-"}</div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="text-xs font-semibold text-teal-400" title="Substation In-Active Count">No Data Sync</div>
                                                                            <div className="font-bold text-center text-lg text-[#c2403c]">{cardData && cardData?.total_src_feeders_no_data_sync_count || "-"}</div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="text-xs font-semibold text-teal-400" title="Substation Active Count">No Data</div>
                                                                            <div className="font-bold text-center text-lg text-[#89929e]">{cardData && cardData?.total_src_feeders_no_data_count || "-"}</div>
                                                                        </div>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </CarouselItem>
                                })
                            }
                        </CarouselContent>
                    </Carousel >
                </>
            }




        </>
    )
}

export default SubstationCards


