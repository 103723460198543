import { UsersListLoading } from 'src/components/ui/UsersListLoading';
import React, { useEffect, useState, useMemo, useRef, memo, useReducer, useCallback } from 'react';
import DistrictFilter from 'src/Modules/Filters/Districts';
import { dropdownOptions, stringifyObjectToRequestQuery } from 'src/helpers';
import DateInput from 'src/components/commons/DateInput';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getFilterReducerState } from 'src/redux/Reducers/filterReducer';
import { SelectDropdown } from 'src/components/commons/DropdownSelect';
import SubStationFilter from 'src/Modules/Filters/Substation';
import TransformerFilter from 'src/Modules/Filters/Transformer';
import { Button } from '../../../../../components/ui/button';

type Props = {}

const GISDashboardMain
    : React.FC<Props> = ({ }) => {

        const [loader, setLoader] = useState(false)
        // const { district_id, _date, tcn_substation_id } = useSelector((state: RootState) => getFilterReducerState(state))
        const [filterType, setfilterType] = useState<any>("")
        const [filterData, setfilterData] = useState<any>({
            district_id: "",
            tcn_substation_id: "",
            tcn_transformer_id: "",
            _date: ""
        })

        const handleSelect = (selectedValue: string) => {
            setfilterType(selectedValue)
        };

        const [date, setDate] = React.useState<Date | undefined>(new Date())

        const iframeRef = useRef<any>(null)

        const BASE_URL = process.env.REACT_APP_URL_GIS;

        // const [url, seturl] = useState<string>(BASE_URL + "/?dist_id=2&feed_id=195");
        const [url, seturl] = useState<string | undefined>(BASE_URL);

        const handleSubmit = () => {
            let defaultQuery = { dtype: 'map' };
            const newFilter = {
                dist_id: filterData?.district_id || null,
                sub_id: filterData?.tcn_substation_id || null,
                Tcn_trans_id: filterData?.tcn_transformer_id || null,
                _date: filterData?._date
            }
            let apiQuery = { ...newFilter, ...defaultQuery }
            const query = stringifyObjectToRequestQuery(apiQuery)
            console.log({ query });

            seturl(BASE_URL + "?" + query)
        }

        // useEffect(() => {

        //     let defaultQuery = { dtype: 'map' };
        //     const newFilter = {
        //         dist_id: district_id || null,
        //         sub_id: tcn_substation_id || null,
        //         _date: _date
        //     }
        //     let apiQuery = { ...newFilter, ...defaultQuery }
        //     const query = stringifyObjectToRequestQuery(apiQuery)
        //     console.log({ query });

        //     seturl(BASE_URL + "?" + query)
        //     return () => { }
        // }, [district_id, _date, tcn_substation_id])

        // console.log(url);

        // const handleLoader = (e: boolean) => {
        //     setLoader(e)
        // }


        // const onsubmit = (e: any) => {
        //     setfilterstate(e);

        //     // if (!e?.dist_id) toast('District required', { type: 'error' })
        //     //else if (!filterstate?.feeder) toast('Feeder required', { type: 'error' })
        //     // else {
        //     // handleLoader(true)
        //     const { dist_id, fd_id, substation_id, _date } = e;
        //     var query = "";
        //     if (dist_id) query = query == "" ? `dist_id=${dist_id}` : query + `&dist_id=${dist_id}&dtype=map`;
        //     if (substation_id) query = query == "" ? `substation_id=${substation_id}` : query + `&substation_id=${substation_id}&dtype=map`;
        //     // if (fd_id) query = query == "" ? `feed_id=${fd_id}` : query + `&feed_id=${fd_id}`;
        //     if (_date) query = query == "" ? `_date=${_date}` : query + `&_date=${_date}&dtype=map`;

        //     const url = BASE_URL + `?` + query;
        //     const newUrl = encodeURI(url);
        //     seturl(newUrl);
        // }


        return <>
            <div className="space-y-2">


                <div className='flex justify-end space-x-2 items-center'>
                    {/* <DistrictFilter onChange={(e: any) => {
                        setfilterstate({ ...filterstate, dist_id: e })
                    }} />
                    <DateInput date={filterstate?._date} selectedDate={(e: any) => {
                        setfilterstate({ ...filterstate, _date: e && format(new Date(e), "yyyy-MM-dd") })
                    }} /> */}
                    <div className=''>
                        <SelectDropdown options={dropdownOptions} onSelect={handleSelect} value={filterType} />
                    </div>
                    {filterType && filterType == "dist" ?
                        <DistrictFilter onChange={(e: any) => {
                            setfilterData({ ...filterData, district_id: e, tcn_substation_id: "", tcn_transformer_id: "" })
                        }} /> : <></>}
                    {filterType && filterType == "substn" ? <>
                        <SubStationFilter onChange={(e: any) => {
                            setfilterData({ ...filterData, tcn_substation_id: e, district_id: "" })
                        }} />
                        <TransformerFilter substation_id={filterData?.tcn_substation_id || 0} onChange={(e: any) => {
                            setfilterData({ ...filterData, tcn_transformer_id: e, district_id: "" })
                        }} />
                    </>
                        : <></>}
                    <DateInput date={filterData?._date} selectedDate={(e: any) => {
                        setfilterData({ ...filterData, _date: e && format(new Date(e), "yyyy-MM-dd") })
                    }}
                    />
                    <Button className=' bg-teal-500  text-white border-white hover:border-transparent hover:text-white hover:bg-teal-300 font-bold' onClick={(e: any) => {
                        handleSubmit()
                    }
                    }>Submit</Button>
                </div>

                <div className='w-full'>
                    <iframe
                        style={{ height: "80vh", width: "100%" }}
                        onLoad={() => setLoader(false)}
                        ref={iframeRef}
                        src={url}
                        className={' w-max-content'}
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full">
                    </iframe>
                </div>
            </div>
        </>
    }

export default GISDashboardMain
