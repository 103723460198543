import React, { useMemo, useState } from 'react'
import TableComponent2 from 'src/components/TableComponent2'
import { FeederEnergyLogDetailsState, QueryRequestProvider, QueryResponseProvider, useQueryResponseData, useQueryResponseLoading } from '../Context'
import { getDatesOfMonth } from 'src/helpers'
import { format } from 'date-fns'
import { useMainContext } from 'src/Modules/TestComponent/MainContext'
import TableComponent3 from 'src/components/TableComponent3'
import { Card } from '../../../../../components/ui/card'
import { ScrollArea, ScrollBar } from '../../../../../components/ui/scroll-area'
import LogBasicDetails from './LogBasicDetails'
import { DatePickerWithRange } from 'src/components/commons/DatePickerWithRange'
import { SelectDropdown } from 'src/components/commons/DropdownSelect'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getFilterReducerState } from 'src/redux/Reducers/filterReducer'
import { AlertCircle } from 'lucide-react'
import LogFilters from './LogFilters'
import { getfeederLogTableReducerState } from 'src/redux/Reducers/feederLogTableReducer'
import ExcelExport from './ExcelExport'




type Props = {}

interface IApiResponseType {
    "WH_TOTAL": number,
    "VARH_TOTAL": number,
    "VAH_TOTAL": number,
    "PF_TOTAL": number,
    "V_A": number,
    "V_B": number,
    "V_C": number,
    "V_AVG": number,
    "V_MAX": number,
    "A_A": number,
    "A_B": number,
    "A_C": number,
    "A_AVG": number,
    "A_MAX": number,
    "ts": string,
    "KWH_TOTAL": number,
    "_date": string,
    "_time": string,
}

const dropdownOptions = [
    { value: 'A_MAX', label: 'Current' },
    { value: 'V_MAX', label: 'Voltage' },
    { value: 'KWH_TOTAL', label: 'KWH' },
]

const Main = (props: Props) => {

    // const { state: contextState, updateState: updateContextState } = useMainContext()
    const { tableData, isLoading } = useSelector((state: RootState) => getfeederLogTableReducerState(state))
    const apiResp: any = useQueryResponseData();
    // const isLoading = useQueryResponseLoading();
    let log_data: any = useMemo(() => tableData, [tableData]);
    const { state, updateState } = FeederEnergyLogDetailsState();


    const [filterType, setfilterType] = useState<any>("")

    let dates = state && state?.filter?.end_date && getDatesOfMonth(state?.filter?.end_date)
    // console.log(dates);
    let complete_dates: any = useMemo(() => dates, [dates])
    // console.log(complete_dates);

    const handleSelect = (selectedValue: string) => {
        setfilterType(selectedValue)
    };



    const times = useMemo(() => {
        var x = 30; //minutes interval
        var times = []; // time array
        var tt = 0; // start time

        //loop to increment the time and push results in array
        for (var i = 0; tt < 24 * 60; i++) {
            var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
            var mm = (tt % 60); // getting minutes of the hour in 0-55 format
            times[i] = ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
            tt = tt + x;
        }
        console.log(times);

        return times;

    }, [])

    const tableDatas = useMemo(() => {
        return times && times.map((t: string) => {
            let rowData = complete_dates && Array.isArray(complete_dates) && complete_dates.map((d: any) => "-")
            if (rowData) {
                rowData = [...rowData];
                let extractDataBasisOnTime = log_data && Array.isArray(log_data) && log_data.filter((d: IApiResponseType) => d._time == t);
                // console.log(log_data.filter((d: IApiResponseType) => console.log(format(new Date(d.ts), "HH:mm"))))
                // console.log(extractDataBasisOnTime);
                extractDataBasisOnTime && Array.isArray(extractDataBasisOnTime) && extractDataBasisOnTime.length > 0 && extractDataBasisOnTime.forEach((d: IApiResponseType) => {
                    const findDateIndex = complete_dates && Array.isArray(complete_dates) && complete_dates.findIndex((x: any) => x == d._date)
                    rowData[findDateIndex] = Number(d[filterType as keyof typeof d] || 0)?.toFixed(2)
                })
                rowData = [t, ...rowData]
                return rowData
            }
            else return []
        })
    }, [log_data, complete_dates, times, filterType])



    // const groupedData = log_data?.reduce((acc: any, entry: any) => {
    //     const date = entry._date;
    //     if (!acc[date]) {
    //         acc[date] = {};
    //     }
    //     const time = entry._time;
    //     acc[date][time] = {
    //         A_MAX: entry.A_MAX,
    //         V_MAX: entry.V_MAX,
    //         KWH_TOTAL: entry.KWH_TOTAL
    //     };
    //     return acc;
    // }, {});

    // console.log(groupedData);


    const tableClasses = useMemo(() => {

        const aggregatedData = log_data?.reduce((acc: any, entry: any) => {
            const date = entry._date;
            if (!acc[date]) {
                acc[date] = {
                    A_MAX: [],
                    V_MAX: [],
                    KWH_TOTAL: []
                };
            }
            acc[date].A_MAX.push(entry.A_MAX);
            acc[date].V_MAX.push(entry.V_MAX);
            acc[date].KWH_TOTAL.push(entry.KWH_TOTAL);
            return acc;
        }, {});

        const result: any = {};

        if (filterType) {
            for (const date in aggregatedData) {
                result[date] = {

                    // A_MAX: Math.max(...aggregatedData[date].A_MAX.filter((val:any) => val > 0)),
                    // V_MAX: Math.max(...aggregatedData[date].V_MAX.filter((val:any) => val > 0)),
                    // KWH_TOTAL: Math.max(...aggregatedData[date].KWH_TOTAL.filter((val:any) => val > 0)),
                    // min_A_MAX: Math.min(...aggregatedData[date].A_MAX.filter((val:any) => val > 0)),
                    // min_V_MAX: Math.min(...aggregatedData[date].V_MAX.filter((val:any) => val > 0)),
                    // min_KWH_TOTAL: Math.min(...aggregatedData[date].KWH_TOTAL.filter((val:any) => val > 0))

                    "bg-primary text-white": Math.max(...aggregatedData[date][filterType].filter((val: any) => val?.toFixed(2) > 0)).toFixed(2),
                    "bg-destructive text-white": Math.min(...aggregatedData[date][filterType].filter((val: any) => val?.toFixed(2) > 0)).toFixed(2)

                };
            }
            console.log({result});
            return result;
        }

    }, [log_data, filterType])


    return (
        <>
            <div className="space-y-2">
                <div className="flex justify-between items-center ">
                    <div className="justify-self-start text-center font-bold font-md">Log Sheet of the Feeders</div>
                    <div className='flex justify-end space-x-2 items-center'>
                        <LogFilters />
                    </div>
                </div>
                <LogBasicDetails />
                <div className="flex justify-between">
                    {/* First child div, justified to the start */}
                    <div className="justify-self-start text-center font-bold font-md"></div>

                    {/* Second and third child divs, justified to the end */}
                    <div className="flex justify-end space-x-2">
                        <div>
                            <SelectDropdown options={dropdownOptions} onSelect={handleSelect} value={filterType} />
                        </div>
                        <ExcelExport />
                        {/* <div>
                        <DatePickerWithRange selectedDate={selectedDate} onDateSelect={handleDateSelect} />
                        </div> */}
                    </div>
                </div>
                {/* <div className='text-center font-bold font-md'>Log Sheet of the Feeders</div> */}
                {filterType !== "" ?
                    <TableComponent3 headers={complete_dates ? ["time/date", ...complete_dates] : []} data={tableDatas} isLoading={isLoading} tableClasses={tableClasses} />
                    : <div className="bg-blue-100 text-blue-700 border border-blue-400 rounded-md px-4 py-3 flex justify-center items-center space-x-2" role="alert">
                        <AlertCircle className='text-info ' /> <span>
                            Please select appropriate filters
                        </span>
                    </div>}
            </div>
        </>
    )
}

const LogDetailsTable: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default LogDetailsTable