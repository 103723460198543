import axios from 'axios'
import { URL_FEEDER_PROJECTION_GRAPH } from 'src/apis'


export const feederProjectionApiData = (query: string): Promise<any> => {
  return axios
    .get(`${URL_FEEDER_PROJECTION_GRAPH}?${query}`)
    .then(async (d: any) => d?.data)
    .catch((e: any) => e?.response?.data)
}







