import React, { useEffect, useMemo } from 'react'
import MainTable from '../Context/TableComp/MainTable'
import { columns } from '../Context/TableComp/columns'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDailySummaryAsync, getDailySummaryReducerState } from 'src/redux/Reducers/dailySummaryReducer'
import { RootState } from 'src/redux/store'
import dayjs from 'dayjs'
import { time_convert } from 'src/helpers/index'


const DailySummary = () => {

    const { page, items_per_page, isLoading, total_records, total, tableData } = useSelector((state: RootState) => getDailySummaryReducerState(state))


    // const data=useMemo(()=>isLoading?[]:tableData.map((cur:any)=>{return {
    //     feeder_name:cur?.feeder_name,

    // }}),[tableData,isLoading])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchDailySummaryAsync())
    }, [])

    const transformedData = useMemo(() => {
        if (isLoading || !tableData) return []; // Add a check for isLoading or if tableData is null
        return tableData.map((item, index) => {
            const availability: any = {};
            item.availability_records.forEach((record: any) => {
                availability[record.reading_date] = time_convert(record.DAY_AVAILABILITY);
            });

            // Check if availability_summary exists before accessing its properties
            const weekComplianceCount = item.availability_summary ? item.availability_summary.week_compliance_count : null;
            const weekCompliancePercentage = item.availability_summary ? item.availability_summary.week_compliance_percentage : null;
            const srNo = index + 1
            return {
                feeder_name: item.feeder_name,
                ...availability,
                week_compliance_count: weekComplianceCount,
                week_compliance_percentage: weekCompliancePercentage,
                district: item.district,
                sr_no: srNo
            };
        });
    }, [isLoading, tableData]);
    console.log(transformedData)
    return (
        <div className='w-full px-6 py-4 '>
            <MainTable columns={columns} data={transformedData || []} />
        </div>
    )
}

export default DailySummary
