import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns'
import { Button } from '../../../../../components/ui/button'
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { DatePickerWithRange } from 'src/components/commons/DatePickerWithRange'
import { FeederEnergyLivePimState } from '../Context'
import { useDispatch } from 'react-redux'

import { fetchFeederPimAsync, setPimEndDate, setPimStartDate } from 'src/redux/Reducers/feederPimReducer'

type Props = {}


const PimFilters = (props: Props) => {

    const { state, updateState } = FeederEnergyLivePimState()
    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>();
    const dispatch = useDispatch()

    // const startDate = state?.filter?.start_date ? parseISO(state?.filter?.start_date) : null;
    // const endDate = state?.filter?.end_date ? parseISO(state?.filter?.end_date) : null;

    // const formattedStartDate = startDate ? format(startDate, "LLL dd, y") : '';
    // const formattedEndDate = endDate ? format(endDate, "LLL dd, y") : '';

    


    // const initialDateRange: any = {
    //     from: formattedStartDate,
    //     to: formattedEndDate
    // };


    // console.log(state)

   

    useEffect(() => {
        if (state?.filter?.start_date && state?.filter?.end_date) {
            // console.log(state?.filter?.start_date, state?.filter?.end_date);
            setSelectedDate({
                from: new Date(state?.filter?.start_date),
                to: new Date(state?.filter?.end_date),
            })
        }
        return () => { }
    }, [state?.filter?.start_date, state?.filter?.end_date])





    const handleDateSelect = (selectedRange: any) => {
        setSelectedDate(selectedRange);

        // console.log(startDate, endDate);

        const formattedStartDate = selectedRange?.from ? format(selectedRange?.from, "yyyy-MM-dd") : '';
        const formattedEndDate = selectedRange?.to ? format(selectedRange?.to, "yyyy-MM-dd") : '';

        dispatch(setPimStartDate(formattedStartDate))
        dispatch(setPimEndDate(formattedEndDate))

    };


    const handleApplyFiltes = () => {
        dispatch(fetchFeederPimAsync())
    }


    return (
        <>
            <div className='flex space-x-2'>
                <DatePickerWithRange selectedDate={selectedDate} onDateSelect={handleDateSelect} />
                <Button onClick={handleApplyFiltes} >Submit</Button>
            </div>
        </>
    )
}

export default PimFilters