import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs"
import Sidebarlayout from './layout/sidebar/Sidebarlayout'
import { useSelector } from 'react-redux'
import { dashboardReducerState } from 'src/redux/Reducers/dashboardSlicer'
import { RootState } from 'src/redux/store'
import { LogOut } from 'lucide-react'
import { useAuth } from 'src/Modules/Auth/Core'

type Props = {}

const HeaderComponent = (props: Props) => {

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))
    const { currentUser, logout } = useAuth();
    return (
        <div>
            <div className='w-full sticky top-0 z-10'>
                <nav className="w-full flex items-center justify-between  bg-gray-200 p-3">
                    <div className="flex items-center justify-cemter text-black mr-6">
                        {/* <svg className="fill-current h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" /></svg> */}
                        <span className="font-semibold text-center text-xl tracking-tight">Feeder Monitoring System</span>
                    </div>
                    {/* <div className="block lg:hidden">
                        <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
                            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                        </button>
                    </div> */}


                    {/* <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto"> */}
                    {/* <div className="text-sm lg:flex-grow"> */}
                    {/* <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
                                Docs
                            </a>
                            <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
                                Examples
                            </a>
                            <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white">
                                Blog
                            </a> */}

                    {/* </div> */}



                    {/* </div> */}


                    <LogOut className='cursor-pointer' onClick={logout} />
                </nav>
            </div>
            <Sidebarlayout />


            {/* <div className='container'> */}
            <div className={`${dashBoardState ? "ml-40 duration-300 container mt-4" : "ml-20 duration-300 container mt-4"}`}>
                <Outlet />
            </div>
        </div>

    )
}

export default HeaderComponent