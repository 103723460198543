const API_URL = process.env.REACT_APP_BASE_URL + "/api/v1";
const REPORT_SERVICE_API_URL =
  process.env.REACT_APP_API_URL_REPORT_SERVICE + "/api/v1";
const BE_SERVICE_API_URL = process.env.REACT_APP_API_URL_BE_SERVICE + "/api/v1";
const AUTH_SERVICE = process.env.REACT_APP_API_URL_AUTH_SERVICE_NEW;
// Filters
export const URL_DISTRICT_FILTER = REPORT_SERVICE_API_URL + "/dd/districts";
export const URL_TCN_SUBSTATION_FILTER =
  API_URL + "/substation/dd/substation-list";
export const URL_GET_TCN_TRANSFORMER_BY_ID =
  API_URL + "/transformer/dd/power-transformer";

// Feeder Monitoring System
export const URL_FEEDER_CARD = API_URL + "/feeder/dashboard";
export const URL_LIVE_PIM_GRAPH = API_URL + "/feeder/live-pim";
export const URL_PEAK_MONITORING_GRAPH = API_URL + "/feeder/day-chart";
export const URL_VIEW_MORE_DETAILS = (ID: string) =>
  API_URL + "/feeder/details" + "/" + ID;
export const URL_FEEDER_PROJECTION_GRAPH = API_URL + "/feeder/projection";
export const URL_FEEDER_LOG_SHEET = API_URL + "/feeder/log-sheet";
export const URL_FEEDER_LOG_SHEET_EXPORT = URL_FEEDER_LOG_SHEET + "/export";
export const URL_DISRICT_PIM_GRAPH = API_URL + "/feeder/pim-analysis-chart";
export const URL_DAILY_DISRICT_TRIP_GRAPH =
  API_URL + "/feeder/feeder-wise-trip";
export const URL_SUBSTATION_PIM_GRAPH =
  API_URL + "/feeder/substation-pim-analysis-chart";
export const URL_SUBSTATION_CARDS = API_URL + "/feeder/dashboard-landing";

// CRON
export const URL_CRON_JOB_SUMMARY = API_URL + "/job/sync/fetch-summary";
export const URL_CRON_JOB_SUMMARY_DETAILS =
  API_URL + "/job/sync/summary-details";
export const URL_CRON_JOB_SUMMARY_DETAILS_EXPORT =
  API_URL + "/job/sync/summary-details/export";

// Auth
export const LOGIN_URL = AUTH_SERVICE + "/login";
export const PROFILE_URL = AUTH_SERVICE + "/profile";

// daily summary
export const DAILY_SUMMARY = API_URL + "/feeder/nerc";
