import { useEffect, useState } from 'react';
import { FeederEnergyDistrictPimState, QueryRequestProvider, QueryResponseProvider, useQueryResponse } from './Context';
import LivePIMGraph from './Components/SubstationWisePIMGraph';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../../components/ui/tabs';
import { endOfDay, format, startOfDay } from 'date-fns';
import DateBymFilter from 'src/components/commons/DateBymFilter';
import SubstationWisePIMGraph from './Components/SubstationWisePIMGraph';
import SubStationFilter from 'src/Modules/Filters/Substation';



type Props = {}

const Main = (props: Props) => {

    const { state, updateState } = FeederEnergyDistrictPimState();

    const fullMonth = format(new Date(), "MMM").toUpperCase();
    const currentYear = format(new Date(), "yy")
    // console.log(currentYear);


    const [monthFilter, setMonthFilter] = useState<string>(`${fullMonth}-${currentYear}`)
    // console.log(monthFilter);



    useEffect(() => {
        if (monthFilter) {
            updateState({ ...state, filter: { ...state?.filter, bym: monthFilter } })
        }
        return () => { }
    }, [monthFilter])

    // console.log(state, monthFilter, currentYear);



    return <>
        <div className='space-y-2'>
            <div className='flex justify-end items-center space-x-2 space-y-2 mb-2'>
                <SubStationFilter onChange={(e: any) => {
                    updateState({ ...state, filter: { ...state?.filter, tcn_substation_id: e } })
                    // refetch()
                }} />
                <DateBymFilter setMonthFilter={setMonthFilter} />
            </div>
            <div className="space-y-4">
                <div className='text-center font-bold font-md'>Substation-wise PIM Analysis</div>
                <SubstationWisePIMGraph />
                {/* <div className='text-center font-bold font-md'>District Wise Daily Feeder Trips</div> */}
                
            </div>
        </div>
    </>
}

const SubstationWisePimMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default SubstationWisePimMain