import React, { useEffect, useMemo } from 'react'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import FormikSelectPicker2 from '../../../components/ui/SelectPicker/FormikSelectPicker2'
import { prepareFilterOptions } from 'src/helpers'
import { useDispatch } from 'react-redux'

type Props = {
    onChange: (data: any) => void,
    isDefault?: boolean,
    feeder_id?: string,
    substation_id?: string,
    servicecenter_id?: string,
    transformer_id?: string,
    value?: string,
    isDisabled?: boolean
}


const Main: React.FC<Props> = ({ onChange, isDefault = false, feeder_id, substation_id, servicecenter_id, transformer_id, value, isDisabled }) => {

    const apiResp = useQueryResponseData();
    const isLoading = useQueryResponseLoading();
    const { response, refetch } = useQueryResponse();
    const data = useMemo(() => prepareFilterOptions(apiResp), [apiResp])
    const { state, updateState } = useQueryRequest();

    useEffect(() => {

        let filter: any = {};
        if (substation_id) filter = { ...filter, 'substation_id': substation_id };
        if (transformer_id) {
            filter = { ...filter, 'transformer_id': transformer_id };
        }
        // else{
        //     filter = { ...filter, 'tcn_substation_id': 0 }
        // }
        // if (feeder_id) filter = { ...filter, 'fd_id': feeder_id };
        // if (transfomer_id) filter = { ...filter, 'dss_id': transfomer_id };

        updateState({
            filter
        })
        onChange(undefined)
        return () => { }
    }, [substation_id, transformer_id])

    console.log(state)
    return (
        // QueryReqpose / reques

        <FormikSelectPicker2
            placeholder='Select Transformer'
            value={value}
            options={data}
            onchange={(e: any) => onChange(e)}
            searchable
        />
    )
}

const TransformerFilter: React.FC<Props> = (props) => {

    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main {...props} />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default TransformerFilter