import React from 'react'
import DailySummary from './Component/DailySummary'

const DailySummaryMain = () => {
    return (
        <div>
            <DailySummary />
        </div>
    )
}

export default DailySummaryMain
