import axios, { AxiosResponse } from 'axios'
import { URL_GET_TCN_TRANSFORMER_BY_ID } from 'src/apis'

export const fetchTcnTransformer = ( query: string): Promise<any | undefined> => {
  return axios
    .get(`${URL_GET_TCN_TRANSFORMER_BY_ID}?${query}`)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response)
}








