import React, { useMemo, useState } from 'react'
import { useQueryResponse, useQueryResponseData, useQueryResponseLoading } from '../Context';
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';


type Props = {}

const DailyFeederTripsGraph = (props: Props) => {
    const apiResp: any = useQueryResponseData();
    const isLoading = useQueryResponseLoading();
    let graph_data: any = useMemo(() => apiResp || [], [apiResp]);
    const [data, setdata] = useState<any>(null)

    // console.log({ graph_data });


    // console.log({apiResp});
    // const table_data = apiResp?.data
    // console.log({table_data});
    // setTableData({ table_data })

    let x_data = graph_data?.x?.feeder_name;
    let interruptions = graph_data.y?.interruptions
    let availabilty_hours = graph_data?.y?.availabilty_hours
    let downtime_hours = graph_data?.y?.downtime_hours


    const options: any = {
        // color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            // right: '20%',
            bottom: '150px',
        },

        legend: {
            data: ['Interruptions', 'Downtime Hours', 'Available Hours']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    interval: 0,
                    rotate: 50,
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Hours',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'Interruptions',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: 'Available Hours',
                type: 'line',
                yAxisIndex: 0,
                data: availabilty_hours,
                color: "#4da64d",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            {
                name: 'Downtime Hours',
                type: 'line',
                yAxisIndex: 0,
                data: downtime_hours,
                color: "#e84d49",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            // {
            //     name: 'Receipt Current Month Paid',
            //     type: 'line',
            //     yAxisIndex: 0,
            //     data: receipt_cm_paid
            // },
            {
                name: 'Interruptions',
                type: 'bar',
                yAxisIndex: 1,
                data: interruptions,
                color: "#b3ffff",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            // {
            //     name: 'Vending Arrears Paid',
            //     type: 'line',
            //     yAxisIndex: 1,
            //     data: vending_arrear_paid
            // },
            // {
            //     name: 'Vending Current Month Paid',
            //     type: 'line',
            //     yAxisIndex: 1,
            //     data: vending_cm_paid
            // }

        ]

    }


    return (
        <>
            {/* {isLoading && <UsersListLoadingCenter key={'test-loader'} />} */}
            <div style={{ width: '100%' }}>
                <ReactEchartsConfig option={options} loading={false} style={{ width: '100%', height: '500px' }} />
            </div>
        </>
    )
}

export default DailyFeederTripsGraph