import React, { useRef, useState } from 'react'
import SubstationCards from './Components/SubstationCards'
import { FeederEnergySubstationCardsState, QueryRequestProvider, QueryResponseProvider } from './Context'
import { Maximize2, Minimize2 } from 'lucide-react'

type Props = {}

const Main = (props: Props) => {

    const { state, updateState } = FeederEnergySubstationCardsState()
    const maximizeRef = useRef(document.documentElement)
    const [maximize, setmaximize] = useState(false)

    return (
        <>

            <div className="p-4">
                <div className='grid justify-end'>
                    {maximize ?
                        <Minimize2 size={16} onClick={() => {
                            setmaximize(false)
                            if (document.fullscreenElement) {
                                document.exitFullscreen()
                            }
                        }} />
                        :
                        <Maximize2 size={16} onClick={() => {
                            setmaximize(true)
                            maximizeRef.current.requestFullscreen()
                            updateState({
                                other: { rr: 300 }
                            })
                        }} />
                    }
                </div>
                <SubstationCards />
                {/* <div className="justify-self-start text-center font-bold font-md">Log Sheet of the Feeders</div>
                <div className='flex justify-end space-x-2 items-center'>
                    <LogFilters />
                </div> */}
            </div>
        </>
    )
}

const SubstationCardsMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default SubstationCardsMain
