import React from 'react'
import { QueryRequestProvider, QueryResponseProvider } from '../Context'
import { Card } from 'src/components/ui/card'
import SummaryDetailsTable from './Components/SummaryDetailsTable'
import ExcelExport from './Components/ExcelExport'

type Props = {}

const Main = (props: Props) => {
    return <>

        <div className='p-2 space-y-2'>
            <div className="flex justify-between items-center ">
                <div className='justify-self-start text-center font-bold'>Load Interval Summary Details</div>
                <div className='flex justify-end space-x-2 items-center'>
                    {/* <SummaryFilters /> */}
                    <ExcelExport />
                </div>
            </div>
            <Card className='space-y-2 p-2'>
                <SummaryDetailsTable />
            </Card>
        </div>
    </>
}

const LoadIntervalSummaryDetailsMain = (props: Props) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default LoadIntervalSummaryDetailsMain