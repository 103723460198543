import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '../popover';
import { Button } from '../button';
import { cn } from 'src/helpers';
import RenderSelectedValues from './RenderSelectedValues';
import { CaretSortIcon } from '@radix-ui/react-icons';
import VirtualListFormMultiSelect from './VirtualListFormMultiSelect';

import { MainContextProvider, useMainContext } from './MainContext';
import { Search, X } from 'lucide-react';
import { Label } from '../label';
import { optionsType } from 'src/types';


type Props = {
    options: optionsType[],
    onchange: (e: any) => void
    searchable?: boolean,
    label?: string,
    placeholder?: string,
    value?: string,
    isLoading?: boolean
}

const Main = ({ options, onchange, searchable, placeholder, value, isLoading }: Props) => {

    const { selectedValues, toggleValue } = useMainContext()
    const popupref = useRef<any>(null);
    const [width, setwidth] = useState(0)
    const [searchText, setsearchText] = useState<string>("")

    console.log({ value, options, selectedValues,isLoading });

    useEffect(() => {
        setwidth(popupref?.current?.clientWidth)
        return () => { }
    }, [popupref])

    let data = useMemo(() => {
        if (searchText !== "") {
            return options.filter((d: optionsType) => d.label.toLowerCase().includes(searchText.toLowerCase()))
        }
        else return options
    }, [options, searchText])

    useEffect(() => {
        selectedValues && onchange(selectedValues && selectedValues.value)
        return () => { }
    }, [selectedValues])

    useEffect(() => {
        if (options ) {
            const selecteOptions = value ? options && options.find((d: optionsType) => d.value.toLowerCase().includes(value.toLowerCase())) : undefined
            toggleValue(selecteOptions)
        }
        return () => { }
    }, [value, options])

    return (
        <div className='w-100 '>
            <Popover >
                <PopoverTrigger disabled={isLoading} asChild>
                    <div
                        key={'multi-picker-trigger'}
                        ref={popupref}
                        role="combobox"
                        className={cn(
                            " flex w-max justify-between items-center px-2 font-normal min-h-[40px] border rounded  ", { isLoading: "pointer-events-none bg-gray-400 " }
                        )}
                    >
                        <div className="whitespace-nowrap">
                            {selectedValues && selectedValues ? <RenderSelectedValues /> : <>
                                {isLoading ? "Loading..." : placeholder}
                            </>}
                        </div>
                        {selectedValues && selectedValues.value ? <X onClick={() => {
                            toggleValue(undefined)
                            onchange(undefined)
                        }} size={16} className='mx-2 text-gray-300 hover:text-gray-600' /> : null}
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </div>
                </PopoverTrigger>
                {width && <PopoverContent className={cn("p-0 ")} style={{ width: "100%" }} align="start">
                    <div className={cn(`relative p-1 z-50 overflow-hidden rounded-md border bg-popover text-popover-foreground  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`, { width: "100%" })}  >
                        <div className='flex flex-col'>
                            {searchable && <div className='flex items-center space-x-1 m-2'>
                                <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                                <input
                                    type="text"
                                    className="focus:outline-none hover:outline-none py-2 text-sm w-full  "
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setsearchText(e.target.value.toLowerCase())
                                    }
                                />
                            </div>}
                            <VirtualListFormMultiSelect
                                data={data}
                                onchange={(e: any) => {
                                    popupref.current?.click();
                                }}
                                selectedValues={selectedValues}
                            />
                        </div>
                    </div>
                </PopoverContent>}
            </Popover>
        </div>
    )
}

const FormikSelectPicker2 = ({ onchange, label, ...rest }: Props) => {
    return <div>
        {label && <Label className={cn("uppercase")}>{label}</Label>}
        <MainContextProvider>
            <Main onchange={onchange} {...rest} />
        </MainContextProvider>
    </div>

}

export default FormikSelectPicker2