import { UsersListLoading } from '../../../../../components/ui/UsersListLoading';
import React, { useMemo } from 'react'
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';
import { useQueryResponseData, useQueryResponseLoading } from '../Context';
import millify from 'millify';
import { toINR } from 'src/helpers';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getfeederProjectionReducerState } from 'src/redux/Reducers/feederProjectionReducer';



type Props = {}

const FeederProjectionGraph = (props: Props) => {

    const colors = ['#ff0000', '#FFA500', '#4da64d', '#007aff'];
    // const isLoading = useQueryResponseLoading();
    // const apiResp: any = useQueryResponseData();

    const { tableData, isLoading } = useSelector((state: RootState) => getfeederProjectionReducerState(state))

    let graph_data: any = useMemo(() => tableData || [], [tableData]);
    console.log(graph_data);


    let x_data = graph_data?.x?._date;

    // let cumulative_total_collection = graph_data?.y?.cumulative_total_collection;
    // let pim = graph_data?.y?.pim;
    let kwh = graph_data?.y?.KWH_TOTAL;




    const options: any = {
        color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            // right: '20%'
        },

        legend: {
            data: ['KWH'],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    interval: 0,
                    rotate: 50,
                    fontSize: 10,
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'KWH',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            // {
            //     type: 'value',
            //     name: 'PIM',
            //     position: 'right',
            //     alignTicks: true,
            //     axisLine: {
            //         show: true,
            //         lineStyle: {
            //             color: '#000000'
            //         }
            //     },
            //     axisLabel: {
            //         formatter: '{value}'
            //     }
            // }
        ],
        series: [
            {
                name: 'KWH',
                type: 'line',
                yAxisIndex: 0,
                data: kwh,
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return ((value?.data).toFixed(2));
                    }
                },

            },

            // {
            //     name: 'PIM',
            //     type: 'line',
            //     yAxisIndex: 1,
            //     data: pim,

            // },
        ]
    }


    return (
        <>
            {isLoading && <UsersListLoading key={'test-loader'} />}
            <div className="flex justify-center items-center">
                <div>
                    <ReactEchartsConfig
                        option={options}
                        loading={false}
                        style={{ width: '1400px', height: '500px' }}
                    />
                </div>
            </div>
        </>
    )
}
export default FeederProjectionGraph