import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useState } from "react";
import { cn } from "src/helpers";
import { Button } from "../../components/ui/button";
import { Calendar } from "../../components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";



type Props = {
  date: any;
  defaultValue?: Date;
  selectedDate: (e: any) => void;
};

const DateInput = ({ date, defaultValue, selectedDate }: Props) => {
  const [isOpen, setisOpen] = useState(false);

  const footer = (
    <div
      className=" flex text-xs cursor-pointer hover:text-primary text-gray-500 my-2"
      onClick={() => {
        setisOpen(false);
        selectedDate(undefined);
      }}
    >
      Close
    </div>
  );

  return (
    <>
      <Popover open={isOpen}>
        <PopoverTrigger  asChild>
    
            <Button
              onClick={() => setisOpen(true)}
              variant={"outline"}
              className={cn(
                " pl-3 text-left font-normal whitespace-nowrap ",
                !date && "text-muted-foreground"
              )}
            >
              <span className="me-2"> {date ? (
                format(new Date(date), "dd/MM/yyyy")
              ) : (
                <span>Select Date</span>
              )}</span>
              <CalendarIcon className="h-4 w-4 opacity-50" />
            </Button>
          
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={new Date(date)}
            onSelect={(e: any) => {
              // console.log({ Calendar: e });
              setisOpen(false);
              selectedDate(e);
            }}
            initialFocus
            footer={footer}
          />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DateInput;
