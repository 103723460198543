import { useEffect, useState } from 'react';
import { FeederEnergyDistrictPimState, QueryRequestProvider, QueryResponseProvider, useQueryResponse } from './Context';
import LivePIMGraph from './Components/DistrictWisePIMGraph';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../../components/ui/tabs';
import DistrictFilter from 'src/Modules/Filters/Districts';
import { endOfDay, format, startOfDay } from 'date-fns';
import DateBymFilter from 'src/components/commons/DateBymFilter';
import DistrictWisePIMGraph from './Components/DistrictWisePIMGraph';
import DailyFeederTripsGraph from './Components/DailyFeederTripsGraph';
import SubStationFilter from 'src/Modules/Filters/Substation';
import { Button } from 'src/components/ui/button';
import { SelectDropdown } from 'src/components/commons/DropdownSelect';
import TransformerFilter from 'src/Modules/Filters/Transformer';
import FeederWisePIMGraph from './Components/FeederWisePIMGraph';
import { UsersListLoading } from 'src/components/ui/UsersListLoading';
import { AlertCircle } from 'lucide-react';


type Props = {}

const dropdownOptions = [
    { value: 'dist', label: 'District' },
    { value: 'substn', label: 'Substation' },
]

const Main = (props: Props) => {

    const { state, updateState } = FeederEnergyDistrictPimState();

    const fullMonth = format(new Date(), "MMM").toUpperCase();
    const currentYear = format(new Date(), "yy")
    const [filterType, setfilterType] = useState<any>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [graphs, setGraphs] = useState<boolean>(false)

    const [filterData, setfilterData] = useState({
        district_id: "",
        tcn_substation_id: "",
        tcn_transformer_id: "",
        bym: `${fullMonth}-${currentYear}`
    })
    // console.log(currentYear);


    const [monthFilter, setMonthFilter] = useState<string>(`${fullMonth}-${currentYear}`)
    // console.log(monthFilter);



    // useEffect(() => {
    //     if (monthFilter) {
    //         updateState({ ...state, filter: { ...state?.filter, bym: monthFilter } })
    //     }
    //     return () => { }
    // }, [monthFilter])

    const handleApplyFiltes = () => {
        setIsLoading(true)
        setGraphs(true)
        let newFilter = { ...state.filter, ...filterData }
        updateState({ ...state, filter: newFilter })
        setIsLoading(false)
        setGraphs(false)
    }

    // console.log(state, monthFilter, currentYear);
    const handleSelect = (selectedValue: string) => {
        // Handle the selected value
        console.log('Selected value:', selectedValue);
        setfilterType(selectedValue)
        // You can set it to state or perform any other actions here
    };
    // console.log(filterType);



    return <>
        <div className='space-y-2'>
            <div className="flex justify-between items-center ">
                <div className='justify-self-start text-center font-bold'>PIM & Energy Analysis Dashboard</div>
                <div className='flex justify-end items-center space-x-2 space-y-2 mb-2'>
                    <div className='mt-2'>
                        <SelectDropdown options={dropdownOptions} onSelect={handleSelect} value={filterType} />
                    </div>
                    {filterType && filterType == "dist" ?
                        <DistrictFilter value={filterData.district_id} onChange={(e: any) => {
                            setfilterData({ ...filterData, district_id: e, tcn_substation_id: "", tcn_transformer_id: "" })
                            //    console.log({e})
                            // refetch()
                        }} /> : <></>}
                    {filterType && filterType == "substn" ? <>
                        <SubStationFilter value={filterData?.tcn_substation_id} onChange={(e: any) => {
                            setfilterData({ ...filterData, tcn_substation_id: e, district_id: "" })
                            // refetch()
                        }} />
                        <TransformerFilter value={filterData?.tcn_transformer_id} substation_id={filterData?.tcn_substation_id} onChange={(e: any) => {
                            setfilterData({ ...filterData, tcn_transformer_id: e, district_id: "" })
                            // refetch()
                        }} /> </> : <></>
                    }
                    <DateBymFilter setMonthFilter={(e: any) => setfilterData({ ...filterData, bym: e })} monthYear={filterData.bym} />
                    <Button onClick={handleApplyFiltes} >Submit</Button>
                </div>
            </div>
            {/* {graphs && graphs ?  */}
            <div className="space-y-4">
                <div className='text-center font-bold font-md'>PIM & Energy Analysis</div>
                <DistrictWisePIMGraph />
                <div className='text-center font-bold font-md'>Feeder Wise Postpaid Prepaid PIM Analysis</div>
                <FeederWisePIMGraph />
                <div className='text-center font-bold font-md'>Feeder Trip Analysis</div>
                <DailyFeederTripsGraph />
            </div>
            {/* :
                    <>
                       {
                            isLoading && isLoading ? <UsersListLoading />
                                : <div className="bg-blue-100 text-blue-700 border border-blue-400 rounded-md px-4 py-3 flex justify-center items-center space-x-2" role="alert">
                                    <AlertCircle className='text-info ' /> <span>
                                        Please select appropriate filters
                                    </span>
                                </div>
                        }
                    </>
            } */}
        </div>
    </>
}

const DistrictWisePimMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default DistrictWisePimMain