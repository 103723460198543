import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "./Reducers/filterReducer";
import feederCardsReducer from "./Reducers/feederCardsReducer";
import feederTripsReducer from "./Reducers/feederTripsReducer";
import dashboardSlicer from "./Reducers/dashboardSlicer";
import feederLogTableReducer from "./Reducers/feederLogTableReducer";
import feederPeakMonitoringReducer from "./Reducers/feederPeakMonitoringReducer";
import feederProjectionReducer from "./Reducers/feederProjectionReducer";
import feederPimReducer from "./Reducers/feederPimReducer";
import cronJobReducer from "./Reducers/cronJobReducer";
import dailySummaryReducer from "./Reducers/dailySummaryReducer";

export const store = configureStore({
  reducer: {
    filterState: filterReducer,
    feederCardsReducerState: feederCardsReducer,
    feederTripsReducerState: feederTripsReducer,
    feederLogTableReducerState: feederLogTableReducer,
    feederPeakMonitoringReducerState: feederPeakMonitoringReducer,
    feederProjectionReducerState: feederProjectionReducer,
    feederPimReducerState: feederPimReducer,
    dashboard: dashboardSlicer,
    cronJobFilterState: cronJobReducer,
    dailySummaryState: dailySummaryReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
