import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface IfilterReducerState {
    district_id: string,
    tcn_substation_id: string,
    tcn_transformer_id: string,
    _date: string,
    filterType:string
}


export const initaiState: IfilterReducerState = {
    district_id: "",
    tcn_substation_id: "",
    tcn_transformer_id:"",
    _date: "",
    filterType:""
}

// export const fetchAllEmployyeKycRequestsAsync: any = createAsyncThunk(
//     'employeeKycRequestsReducer/fetchAll',
//     async (_, thunkApi: any) => {
//         try {
//             thunkApi.dispatch(resetError());
//             const response: any = await fetchAllEmployeeKycRequest();


//             if (response.status === 200 && response.data) {
//                 return response; // Resolve the Promise with the successful response
//             }
//             else {
//                 const errorMessage = "";//response.data?.message || 'An error occurred during login';
//                 return thunkApi.rejectWithValue(errorMessage);
//             }

//         } catch (_error) {
//             // Handle other errors, such as network errors
//             const error = _error as Error | AxiosError;
//             if (axios.isAxiosError(error)) {
//                 console.log("catch errorr with axios");
//                 thunkApi.dispatch(setError(error.response?.data.message));
//                 return thunkApi.rejectWithValue(error.response?.data.message);
//             }
//             thunkApi.dispatch(setError(error.message));
//             return thunkApi.rejectWithValue(error.message);
//         }
//     }
// );


export const filterReducer = createSlice({
    name: 'filterReducer',
    initialState: initaiState,
    reducers: {
        setDistrict: (state, action: PayloadAction<any>) => {
            state.district_id = action.payload;
        },
        setSubstation: (state, action: PayloadAction<any>) => {
            state.tcn_substation_id = action.payload;
        },
        setTransformer: (state, action: PayloadAction<any>) => {
            state.tcn_transformer_id = action.payload;
        },
        setDate: (state, action: PayloadAction<any>) => {
            state._date = action.payload;
        },
        setType: (state, action: PayloadAction<any>) => {
            state.filterType = action.payload;
        },
        reseteState: (state) => {
            state = initaiState
        },
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(fetchAllEmployyeKycRequestsAsync.fulfilled, (state, { payload }) => {
    //             state.isLoading = true;
    //             state.allRequests = payload.data;
    //             state.error = false;
    //             state.messasge = undefined
    //         })
    //         .addCase(fetchAllEmployyeKycRequestsAsync.pending, (state) => {
    //             state.isLoading = false;
    //             state.isLoading = true
    //         })
    //         .addCase(fetchAllEmployyeKycRequestsAsync.rejected, (state, { payload }) => {
    //             state.error = true;
    //             state.messasge = payload;
    //             state.isLoading = false;
    //             state.isLoading = false
    //         })
    // }
});

export const { setDistrict, setSubstation, setTransformer, setDate, setType, reseteState } = filterReducer.actions;

export const getFilterReducerState = (state: RootState) => state.filterState;

export default filterReducer.reducer;