import { UsersListLoading } from '../../../../../components/ui/UsersListLoading';
import React, { useMemo } from 'react'
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';
import { FeederEnergyLivePimState, useQueryResponseData, useQueryResponseLoading } from '../Context';
import millify from 'millify';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getfeederPimReducerState } from 'src/redux/Reducers/feederPimReducer';

type Props = {}

const LivePIMGraph = (props: Props) => {

    const { state, updateState: updateThisState } = FeederEnergyLivePimState()
    const colors = ['#ff0000', '#FFA500', '#4da64d', '#007aff '];
    // const isLoading = useQueryResponseLoading();
    // const apiResp: any = useQueryResponseData();
    const { tableData, isLoading } = useSelector((state: RootState) => getfeederPimReducerState(state))
    let graph_data: any = useMemo(() => tableData || [], [tableData]);
    // console.log(graph_data);
    // console.log(state);
    

    let x_data = graph_data?.x?._date;
    let cumulative_total_collection = graph_data?.y?.cumulative_total_collection;
    let pim = graph_data?.y?.pim;
    let kwh = graph_data?.y?.KWH_TOTAL;

    const options: any = {
        color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            // right: '20%',
            bottom: '150px',
        },

        legend: {
            data: ['Cummulative Collection', 'PIM', 'KWH'],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    interval: 0,
                    rotate: 90,
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Cummulative Collection',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'PIM',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}',

                }
            }
        ],
        series: [
            {
                name: 'Cummulative Collection',
                type: 'line',
                yAxisIndex: 0,
                data: cumulative_total_collection,
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            // {
            //     name: 'Loss Unit',
            //     type: 'line',
            //     yAxisIndex: 1,
            //     // data: loss_unit
            // },
            {
                name: 'KWH',
                type: 'line',
                yAxisIndex: 0,
                data: kwh,
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'PIM',
                type: 'line',
                yAxisIndex: 1,
                data: pim,
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
        ]
    }


    return (
        <>
            {isLoading && <UsersListLoading key={'test-loader'} />}
            <div className="grid justify-items-center">
                <div>
                    {
                        <ReactEchartsConfig
                            option={options}
                            loading={false}
                            style={{ width: '1200px', height: '500px' }}

                        />
                    }
                </div>
            </div>
        </>
    )
}
export default LivePIMGraph