/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
  ERROR_CODES,
  PaginationState,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  print,
  stringifyObjectToRequestQuery,
  stringifyRequestQuery
} from 'src/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { subStationPimApiData } from './_request'
import { useLocation } from 'react-router-dom'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children, props, type, postdata }) => {

  const { state, updateState } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])


  const { search } = useLocation();

  useEffect(() => {

    const params = search && search?.split('?')[1].split("&");
    // console.log({ params });
    let searchParams: any = {};

    params && params.forEach((o: any) => {
      let splitParam = o?.split("=");
      searchParams[splitParam[0]] = splitParam[1];
    });

    // console.log({ MainContextSearchParams: searchParams });

    updateState({
      ...state,
      filter: {
        ...searchParams
      }
    })

    return () => { }

  }, [search])




  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `FEEDER-ENERGY-SUBSTATION-PIM-${query}`,
    async () => {

      // let dummy_query = {
      //   "feeder_code": "ENTCN03T21-33F01",
      //   "start_date": "2023-11-01",
      //   "end_date": "2023-11-30",
      // }
      // // const final_query = stringifyObjectToRequestQuery(dummy_query);
      // // let final_query = {...state?.filter}
      // // console.log(final_query);

      const newstate = state?.filter && JSON.parse(JSON.stringify(state?.filter));
      // console.log({ newstate });
      // // delete newstate["_fkey"];
      delete newstate["items_per_page"];
      delete newstate["page"];
      let table_query = { ...newstate }
      // print(newstate)
      const queryString = stringifyObjectToRequestQuery(table_query);
      console.log(queryString);
      if (state?.filter?.tcn_substation_id && state?.filter?.tcn_substation_id)
        return subStationPimApiData(queryString);
    },
    {
      cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false,
      refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      //  staleTime: 5000,
    }
  )

  let responseData: any = useMemo(() => response, [response])

  let isError = useMemo(() => ERROR_CODES.includes(responseData?.code) ? true : false, [responseData])
  let message = useMemo(() => ERROR_CODES.includes(responseData?.code) ? responseData?.message : '', [responseData])

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query, isError, message }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()

  if (!response || !response?.payload || !response?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.payload?.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination
}

