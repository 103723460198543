import React, { useEffect, useState, useMemo, useRef, memo, useReducer, useCallback } from 'react';
import { MoreChartsCard } from './MoreChartsCard';
import { useLocation } from 'react-router-dom';


type Props = {

}

const MoreChartsDashboardMain: React.FC<Props> = ({ }) => {
    const [searchParamsValues, setsearchParamsValues] = useState<any>()

    const { search } = useLocation();

    useEffect(() => {

        const params = search && search?.split('?')[1].split("&");
        // console.log({ params });
        let searchParams: any = {};

        params && params.forEach((o: any) => {
            let splitParam = o?.split("=");
            searchParams[splitParam[0]] = splitParam[1];
        });

        setsearchParamsValues(searchParams)

        // updateState({
        //     ...state,
        //     filter: {
        //         ...searchParams
        //     }
        // })

        return () => { }

    }, [search])

    console.log(searchParamsValues);
    

    return <>
        <div className="flex flex-wrap gap-4 mt-4">
            {/* <MoreChartsCard title='Feeder Projection' description='Feeder Projection Analysis' link={`/more-charts/projection?feeder_code=${searchParamsValues?.feeder_code && searchParamsValues?.feeder_code}&_date=${searchParamsValues?._date && searchParamsValues?._date}&mg_id=${searchParamsValues?.mg_id && searchParamsValues?.mg_id}`} /> */}
            {/* <MoreChartsCard title='PIM & Energy Dashboard' description='Collection SC-wise Analysis' image='abstract-4.svg' link='/more-charts/district-wise-pim' /> */}
            <MoreChartsCard title='Feeder Trips Dashboard' description='Collection Feeder-wise Analysis' image='abstract-4.svg' link='/more-charts/cards/dash/daliy-district-wise-trips' />
            {/* <MoreChartsCard title='Substation-wise PIM Dashboard' description='Collection Feeder-wise Analysis' image='abstract-4.svg' link='/more-charts/substation-wise-pim' /> */}
            {/* <MoreChartsCard title='Collection On Field Dashboard' description='Collection On Field Analysis' image='abstract-4.svg' link='/colections/pages/collection-on-field' /> */}
        </div>
    </>
}

export default MoreChartsDashboardMain