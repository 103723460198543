
import { ColumnDef } from "@tanstack/react-table"
import dayjs from "dayjs"

import { Badge } from "src/components/ui/badge"

import { convertToCamelCase, getFormatToDateTimeWithoutSecondsTogether } from "src/helpers"



export type DailySummaryTable = {
    feeder_name: string,
    week_compliance_count: number,
    week_compliance_percentage: number
    district: string,
    sr_no: number
}

function getPreviousSevenDays() {
    let dates = [];
    let currentDate = new Date();


    for (var i = 6; i >= 0; i--) {
        let dateCopy = new Date(currentDate);

        dateCopy.setDate(dateCopy.getDate() - i);

        let formattedDate = dateCopy.toISOString().slice(0, 10);

        dates.push({ date: formattedDate });
    }
    return dates;
}



const dt = getPreviousSevenDays();

const cdColumns = dt.map(dateObject => ({
    accessorKey: dayjs(new Date(dateObject.date)).format('YYYY-MM-DD'),
    header: dayjs(new Date(dateObject.date)).format('DD-MMM-YYYY')
}));

export const columns: ColumnDef<DailySummaryTable>[] = [
    {
        accessorKey: "sr_no",
        header: "",
        columns: [
            {
                accessorKey: "sr_no",
                header: "Sr No.",

            }
        ],

    },
    {
        accessorKey: "feeder_name",
        header: "",
        columns: [
            {
                accessorKey: "feeder_name",
                header: "Feeder Name",
                cell: (({ row }) => {
                    let name: string = row.getValue('feeder_name');

                    return <div className="text-left whitespace-nowrap w-full">{name}</div>
                })
            }
        ],

    },
    {
        accessorKey: "district",
        header: "",
        columns: [
            {
                accessorKey: "district",
                header: "Decriptions of Feeder Location",
                cell: (({ row }) => {
                    let name: string = row.getValue('district');

                    return <div className="text-left whitespace-nowrap w-full">{name}</div>
                })

            }
        ],
    },
    {
        accessorKey: "_type",
        header: "",
        columns: [
            {
                accessorKey: "_type",
                header: "Names of major streets served by the Feeder",


            }
        ],
    },
    {
        accessorKey: "week_compliance_count",
        header: "Hours of Availability of Band-A Feeders",

        columns: cdColumns
    },

    {
        accessorKey: "week_compliance_count",
        header: "",
        columns: [
            {
                accessorKey: "week_compliance_count",
                header: "Summary of Weekly Compliance",

                cell: (({ row }) => {
                    let per: number = row.getValue('week_compliance_count');

                    return <div className="text-center">{per}{" "}Out of 7</div>
                })
            }
        ],
    },
    {
        accessorKey: "week_compliance_percentage",
        header: "",
        columns: [
            {
                accessorKey: "week_compliance_percentage",
                header: "Percentage Aggregate Weekly Compliace",
                cell: (({ row }) => {
                    let per: number = row.getValue('week_compliance_percentage');

                    return <div className="text-center">{(per * 100)?.toFixed(2)}%</div>
                })
            },


        ],
    },



]

