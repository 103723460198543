import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getCronJobFilterReducerState } from 'src/redux/Reducers/cronJobReducer'
import { RootState } from 'src/redux/store'
import MainTable from '../Context/TableCom/MainTable'
import { columns } from '../Context/TableCom/columns'

type Props = {}

const SummaryTable = (props: Props) => {
    const { tableData, isLoading, page, items_per_page } = useSelector((state: RootState) => getCronJobFilterReducerState(state))
    // console.log({ tableData });

    let tableDatas: any = useMemo(() => tableData || [], [tableData])

    tableDatas = useMemo(() => tableDatas && tableDatas?.map((o: any, i: any) => {
        return {
            ...o,
            sr_no: (i + 1) + (items_per_page * (page == 1 ? 0 : page - 1))
        }
    }), [tableDatas, page, items_per_page])

    return (
        <>
            <MainTable columns={columns} data={tableDatas || []} />
        </>
    )
}

export default SummaryTable
