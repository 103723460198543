import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  QueryState,
  stringifyObjectToRequestQuery,
  stringifyRequestQuery,
} from "src/helpers";
import axios, { AxiosError } from "axios";
import dayjs from "dayjs";
import { dailySummaryDetailsApiData } from "src/Modules/TestComponent/Component/DailySummary/Context/core/request";

interface IDailySummaryReducerState extends QueryState {
  isLoading: boolean;
  error: boolean;
  message: string | undefined;
  tableData: any[];
  summaryData: any[];
  request_status: undefined | number;
  start_date: string;
  end_date: string;
  service_band: string;
  page: number;
  items_per_page: 10 | 30 | 50 | 100;
}

const dt = new Date();
const endDate = dayjs(new Date()).format("YYYY-MM-DD");
const startDate = dayjs(dt.setDate(dt.getDate() - 7)).format("YYYY-MM-DD");

export const initaiState: IDailySummaryReducerState = {
  isLoading: false,
  error: false,
  message: undefined,
  tableData: [],
  summaryData: [],
  request_status: undefined,

  start_date: startDate,
  end_date: endDate,
  service_band: "A",
  page: 1,
  items_per_page: 30,
};

const transformToQueryString = (data: any) => {
  // console.log({ data });

  const filterkeys: string[] = [
    "_type",
    "start_date",
    "end_date",
    "service_band",
  ];
  const f_keys: string[] = ["status"];
  const q_keys: string[] = ["req_no", "code", "name"];

  let filters: any = {};
  let _f: any = {};
  let _q: any = {};

  data &&
    Object.entries(data).map(([key, value]) => {
      if (filterkeys.length > 0 && filterkeys.includes(key)) {
        filters[key] = value;
      } else if (f_keys.length > 0 && f_keys.includes(key)) {
        _f[key] = value;
      } else if (q_keys.length > 0 && q_keys.includes(key)) {
        _q[key] = value;
      }
    });

  // console.log({ filters, _f, _q });

  return stringifyRequestQuery({
    filter: filters,
    f: _f,
    q: _q,
    items_per_page: data.items_per_page,
    page: data.page,
  });
};

export const fetchDailySummaryAsync: any = createAsyncThunk(
  "DailySummaryAsyncReducer/fetchAll",
  async (_, thunkApi: any) => {
    try {
      const getFilterState = thunkApi.getState().dailySummaryState;
      console.log(getFilterState);
      const newFilterState = { ...getFilterState };
      delete newFilterState["items_per_page"];
      delete newFilterState["page"];
      const queryString = transformToQueryString(newFilterState);
      console.log({ queryString });
      // if(getFilterState?.startDate && getFilterState?.endDate)

      let response: any = await dailySummaryDetailsApiData(queryString);

      if (response.code === 200 && response.data) {
        console.log(response);
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = ""; //response.data?.message || 'An error occurred during login';
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      // Handle other errors, such as network errors
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.log("catch errorr with axios");
        thunkApi.dispatch(setError(error.response?.data.message));
        return thunkApi.rejectWithValue(error.response?.data.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const dailySummaryReducer = createSlice({
  name: "dailySummaryReducer",
  initialState: initaiState,
  reducers: {
    setPage: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
    },
    setItemsPerPage: (state, action: PayloadAction<any>) => {
      state.items_per_page = action.payload;
    },
    setPaggination: (state, action: PayloadAction<any>) => {
      state.items_per_page = action.payload.pageSize;
      state.page = action.payload.pageIndex;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload?.data?.message;
      state.request_status = action.payload?.status;
    },
    resetError: (state) => {
      state.error = false;
      state.message = undefined;
    },
    reseteState: (state) => {
      state = initaiState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDailySummaryAsync.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tableData = payload;
      state.error = false;
      state.message = undefined;
      state.items_per_page = payload?.payload?.pagination?.items_per_page;
      state.page = payload?.payload?.pagination?.page;
      state.total_records = payload?.payload?.pagination?.total_records;
      state.total = payload?.payload?.pagination?.total;
    });
    builder.addCase(fetchDailySummaryAsync.pending, (state) => {
      state.error = false;
      state.message = "";
      state.tableData = [];
      state.isLoading = true;
    });
    builder.addCase(fetchDailySummaryAsync.rejected, (state, { payload }) => {
      state.error = true;
      state.message = payload;
      state.isLoading = false;
    });
  },
});

export const {
  setError,
  resetError,
  reseteState,
  setItemsPerPage,
  setPage,
  setPaggination,
} = dailySummaryReducer.actions;

export const getDailySummaryReducerState = (state: RootState) =>
  state.dailySummaryState;

export default dailySummaryReducer.reducer;
