import { ArrowUpRight, ArrowUpRightSquare } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';


type Props = {
    image?: string;
    title: string;
    description: string;
    link: string;
};

const MoreChartsCard: React.FC<Props> = ({ image, title, description, link }) => {
    const navigate = useNavigate();

    return (
        <div className="bg-white rounded-lg mx-2 flex flex-col flex-1 border" style={{
            backgroundPosition: 'right top',
            backgroundSize: '100px',
            backgroundRepeat:'no-repeat',
            backgroundImage: `url(${'/media/svg/shapes/abstract-4.svg'})`
        }}>
            <div className="p-3 flex flex-col flex-1">
                <a href="#" className="font-bold text-dark  text-primary-hover text-sm">
                    {title}
                </a>
                {/* <p className="font-semibold text-dark text-xs">{description}</p> */}
                <div className="flex justify-between mt-5">
                    <div>
                        <button onClick={() => navigate(link)} className="bg-blue-200  hover:bg-blue-400 text-blue-700 rounded py-2 px-4 text-sm font-bold">Open</button>
                    </div>
                    <ArrowUpRightSquare onClick={() => window.open(link, '_blank')} size={24} className="text-blue-500" />
                </div>
            </div>
        </div>
    );
};

export { MoreChartsCard };
