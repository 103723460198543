import React, { useMemo, useState } from 'react';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue as SelectValueComponent,
} from '../../components/ui/select';
import { optionsType } from 'src/types';

type Option = {
    value: string;
    label: string;
};

type Props = {
    options: Option[];
    onSelect: (selectedValue: string) => void;
    value?: string
};

const SelectValue: React.FC<{ placeholder: string; value: string }> = ({
    placeholder,
    value,
}) => {
    return <SelectValueComponent placeholder={placeholder}>{value}</SelectValueComponent>;
};

export function SelectDropdown({ options, onSelect, value }: Props) {

    // console.log({ options, value })

    const handleSelectChange = (event: any) => {
        onSelect(event);
    };

    const label: string = useMemo(() => options && options.find((x: Option) => x.value === value)?.label || "", [value])

    return (
        <Select value={value} onValueChange={handleSelectChange} >
            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a filter" value={label} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {options.map((option) => (
                        <SelectItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
}
