import React, { useEffect, useMemo, useState } from 'react'
import { FeederEnergyDistrictPimState, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from '../Context';
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';
import { getStartEndDate, getStartDateAndEndDateOfMonth, stringifyObjectToRequestQuery, getMonthRange } from 'src/helpers';
import { districtPimApiData } from '../../DailyFeederTrips/Context/core/_request';


type Props = {}

const DailyFeederTripsGraph = (props: Props) => {


    const { state, updateState } = FeederEnergyDistrictPimState()
    console.log(state);
    // const apiResp: any = useQueryResponseData();
    const isLoading = useQueryResponseLoading();

    const [data, setdata] = useState<any>(null)

    useEffect(() => {
        fetchFeederTripsData()
        return () => { }
    }, [state?.filter])


    const fetchFeederTripsData = async () => {

        let newState = { ...state?.filter }
        // console.log(newState);

        // const { startDate, endDate } = getStartEndDate(newState?.bym && newState?.bym);
        // const result = getStartEndDate(newState?.bym && newState?.bym);
        const result = getMonthRange(newState?.bym && newState?.bym)
        // console.log(result?.startDate, result?.endDate);

        let newFilter = { district_id: newState?.district_id, start_date: result?.startDate, end_date: result?.endDate, tcn_substation_id: newState?.tcn_substation_id, tcn_transformer_id: newState?.tcn_transformer_id }


        const query = stringifyObjectToRequestQuery(newFilter && newFilter);
        console.log(query);

        if (state?.filter?.district_id || state?.filter?.tcn_substation_id || state?.filter?.tcn_transformer_id) {
            const apiResp: any = await districtPimApiData(query)
            let chartData = apiResp?.data
            // console.log(chartData);

            if (chartData) {

                setdata(chartData)

                // console.log(items);
            }
        }
    }

    let graph_data: any = useMemo(() => data || [], [data]);


    // console.log({ graph_data });


    // console.log({apiResp});
    // const table_data = apiResp?.data
    // console.log({table_data});
    // setTableData({ table_data })

    let x_data = graph_data?.x?.feeder_name;
    let interruptions = graph_data.y?.interruptions
    let availabilty_hours = graph_data?.y?.availabilty_hours
    let downtime_hours = graph_data?.y?.downtime_hours


    const options: any = {
        // color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            // right: '20%',
            bottom: '150px',
        },

        legend: {
            data: ['Interruptions', 'Downtime Hours', 'Available Hours']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    interval: 0,
                    rotate: 50,
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Hours',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'Interruptions',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: 'Available Hours',
                type: 'line',
                yAxisIndex: 0,
                data: availabilty_hours,
                color: "#4da64d",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            {
                name: 'Downtime Hours',
                type: 'line',
                yAxisIndex: 0,
                data: downtime_hours,
                color: "#e84d49",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            // {
            //     name: 'Receipt Current Month Paid',
            //     type: 'line',
            //     yAxisIndex: 0,
            //     data: receipt_cm_paid
            // },
            {
                name: 'Interruptions',
                type: 'bar',
                yAxisIndex: 1,
                data: interruptions,
                color: "#b3ffff",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            // {
            //     name: 'Vending Arrears Paid',
            //     type: 'line',
            //     yAxisIndex: 1,
            //     data: vending_arrear_paid
            // },
            // {
            //     name: 'Vending Current Month Paid',
            //     type: 'line',
            //     yAxisIndex: 1,
            //     data: vending_cm_paid
            // }

        ]

    }


    return (
        <>
            {/* {isLoading && <UsersListLoadingCenter key={'test-loader'} />} */}
            <div >
                <ReactEchartsConfig option={options} loading={false} style={{ width: '1400px', height: '500px' }} />
            </div>
        </>
    )
}

export default DailyFeederTripsGraph