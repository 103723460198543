import { UsersListLoading } from '../../../../../components/ui/UsersListLoading';
import React, { useMemo } from 'react'
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';
import { useQueryResponseData, useQueryResponseLoading } from '../Context';
import millify from 'millify';

type Props = {}

const DistrictWisePIMGraph = (props: Props) => {

    const colors = ['#ff0000', '#FFA500', '#4da64d', '#007aff'];
    const isLoading = useQueryResponseLoading();
    const apiResp: any = useQueryResponseData();
    let graph_data: any = useMemo(() => apiResp, [apiResp]);
    // console.log(graph_data);
    let energy_input_sum = useMemo(() => graph_data?.y?.energy_input.map((_: any, idx: any) => graph_data?.y?.loss_units[idx] + graph_data?.y?.billed_units[idx] + graph_data?.y?.vending_units[idx]), [graph_data])
    // console.log(energy_input_sum);


    let x_data = graph_data?.x?.feeder;
    let energy_input = graph_data?.y?.energy_input;
    let pim = graph_data?.y?.pim;
    let loss_units = graph_data?.y?.loss_units;
    let billed_units = graph_data?.y?.billed_units;
    let vending_units = graph_data?.y?.vending_units;



    const options: any = {
        color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            // right: '30%',
            bottom: '150px',
        },

        legend: {
            data: ['PIM', 'Loss Units', 'Billed Units', 'Vending Units'],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    interval: 0,
                    rotate: 50,
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Energy Input',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'PIM',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}',

                }
            }
        ],
        series: [

            {
                name: 'Loss Units',
                type: 'bar',
                stack: 'a',
                yAxisIndex: 0,
                data: loss_units,
                label: {
                    show: false,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'Billed Units',
                type: 'bar',
                stack: 'a',
                yAxisIndex: 0,
                data: billed_units,
                label: {
                    show: false,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            {
                name: 'Vending Units',
                type: 'bar',
                stack: 'a',
                yAxisIndex: 0,
                data: vending_units,
                label: {
                    show: true,
                    position: 'top',
                    formatter: (params: any) => {
                        // console.log(params);
                        
                        const dataIndex = params?.dataIndex;
                        return millify(totals[dataIndex]);
                    }
                },

            },
            {
                name: 'PIM',
                type: 'line',
                yAxisIndex: 1,
                data: pim,
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
            },
            // {
            //     name: 'Energy Input',
            //     type: 'bar',
            //     stack: 'a',
            //     yAxisIndex: 0,
            //     data: energy_input_sum,
            //     label: {
            //         show: true,
            //         position: 'bottom',
            //         formatter: function (value: any) {
            //             return millify(value?.data);
            //         },
            //     },

            //     itemStyle: {
            //         // Set the transparency of the bars to hide them visually
            //         color: 'transparent',
            //         opacity: 0,
            //     },

            // },
            // {
            //     name: 'Energy Input',
            //     type: 'bar',
            //     stack: 'a',
            //     yAxisIndex: 0,
            //     data: energy_input_sum,
            //     label: {
            //         show: true,
            //         position: 'top',
            //         formatter: function (value: any) {
            //             return millify(value?.data);
            //         },
            //         color: '#C92222',
            //         zIndex: 1452369,
            //     },

            //     // Set the transparency of the bars to hide them visually
            //     itemStyle: {
            //         color: 'transparent',
            //         opacity: 0,
            //     },
            //     emphasis: {
            //         focus: 'series' // Ensure that label is focused on the series
            //     },
            //     // This 'silent' property hides the bar from rendering
            //     silent: true,
            // },
        ]
    }
    const totals = options.series.reduce((acc: any, cur: any) => {
        let { data } = cur;

        data?.forEach((value: any, index: any) => {
            acc[index] = (acc[index] || 0) + value;
        });
        return acc;
    }, []);


    return (
        <>
            {isLoading && <UsersListLoading key={'test-loader'} />}
            <div className="">
                <div>
                    {
                        <ReactEchartsConfig
                            option={options}
                            loading={false}
                            style={{ width: '1400px', height: '500px' }}

                        />
                    }
                </div>
            </div>
        </>
    )
}
export default DistrictWisePIMGraph