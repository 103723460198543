import { UsersListLoading } from '../../../../../components/ui/UsersListLoading';
import React, { useMemo } from 'react'
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';
import { useQueryResponseData, useQueryResponseLoading } from '../Context';
import { getfeederTripsReducerState } from 'src/redux/Reducers/feederTripsReducer';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';



type Props = {}

const TripGraph = (props: Props) => {

    // const colors = ['#ff0000', '#4da64d', '#FFA500'];
    const { tableData, isLoading } = useSelector((state: RootState) => getfeederTripsReducerState(state))
    // const isLoading = useQueryResponseLoading();
    const apiResp: any = useQueryResponseData();
    let graph_data: any = useMemo(() =>  apiResp || [], [apiResp]);
    console.log(graph_data);


    let x_data = graph_data?.x?.feeder_name;
    let interruptions = graph_data?.y?.interruptions;
    let availabilty = graph_data?.y?.availabilty_hours;
    let downtime = graph_data?.y?.downtime_hours;

    const options: any = {
        // color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            // right: '20%',
            bottom: '150px',
        },

        legend: {
            data: ['Interruptions', 'Availablity Hours', 'Downtime Hours'],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    interval: 0,
                    rotate: 50,
                    fontSize: 10,
                    // width:100
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Interruptions',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'Hours',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: 'Interruptions',
                type: 'bar',
                yAxisIndex: 0,
                data: interruptions,
                color: "#b3ffff",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },

            {
                name: 'Availablity Hours',
                type: 'line',
                yAxisIndex: 1,
                data: availabilty,
                color: "#4da64d",
                label: {
                    normal: {
                        show: true,
                        position: 'right'
                      }
                  }
            },
            {
                name: 'Downtime Hours',
                type: 'line',
                yAxisIndex: 1,
                data: downtime,
                color: '#ff0000',
                label: {
                    normal: {
                        show: true,
                        position: 'left'
                      }
                  }
            },
        ]
    }


    return (
        <>
            {/* {isLoading && <UsersListLoading key={'test-loader'} />} */}
            <div className="flex justify-center items-center ">
                <div>
                    {
                        <ReactEchartsConfig
                            option={options}
                            loading={false}
                            style={{ width: '1400px', height: '550px' }}
                        />
                    }
                </div>
            </div>
        </>
    )
}
export default TripGraph