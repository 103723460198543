import { UsersListLoading } from '../../../../../components/ui/UsersListLoading';
import React, { useMemo } from 'react'
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';
import { useQueryResponseData, useQueryResponseLoading } from '../Context';
import millify from 'millify';

type Props = {}

const SubstationWisePIMGraph = (props: Props) => {

    // const colors = ['#FFA500', '#007aff'];
    const isLoading = useQueryResponseLoading();
    const apiResp: any = useQueryResponseData();
    let graph_data: any = useMemo(() => apiResp, [apiResp]);
    // console.log(graph_data);
    // let energy_input_sum = useMemo(() => graph_data?.y?.energy_input.map((_: any, idx: any) => graph_data?.y?.loss_units[idx] + graph_data?.y?.billed_units[idx] + graph_data?.y?.vending_units[idx]), [graph_data])
    // console.log(energy_input_sum);


    let x_data = graph_data?.x?.feeder;
    let energy_input = graph_data?.y?.energy_input;
    let pim = graph_data?.y?.pim;
    // let loss_units = graph_data?.y?.loss_units;
    // let billed_units = graph_data?.y?.billed_units;
    // let vending_units = graph_data?.y?.vending_units;

    const options: any = {
        // color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            right: '20%'
        },

        legend: {
            data: ['PIM', 'Energy Input'],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    interval: 0,
                    rotate: 50,
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Energy Input',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'PIM',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}',

                }
            }
        ],
        series: [


            {
                name: 'PIM',
                type: 'line',
                yAxisIndex: 1,
                data: pim,
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return (millify(value?.data));
                    }
                },
                color:'#FFA500'
            },
            {
                name: 'Energy Input',
                type: 'bar',
                yAxisIndex: 0,
                data: energy_input,
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (value: any) {
                        return millify(value?.data);
                    },
                },

                color:'#61ADFF'

            },
        ]
    }


    return (
        <>
            {isLoading && <UsersListLoading key={'test-loader'} />}
            <div className="">
                <div>
                    {
                        <ReactEchartsConfig
                            option={options}
                            loading={false}
                            style={{ width: '1400px', height: '500px' }}

                        />
                    }
                </div>
            </div>
        </>
    )
}
export default SubstationWisePIMGraph