
import { ColumnDef } from "@tanstack/react-table"

import { Badge } from "src/components/ui/badge"

import { convertToCamelCase, getFormatToDateTimeWithoutSecondsTogether } from "src/helpers"

import ActionComp from "./ActionComp"
import TransformerDetails from "./TransformerDetails"
import MarketerDetails from "./MarketerDetails"
import MeterDetails from "./MeterDetails"
import StatusDetails from "./StatusDetails"
import MiscDetails from "./MiscDetails"
import { BadgeCheck, XCircle } from "lucide-react"


export type ReportTable = {
  id: number,
  start_date: string,
  end_date: string,
  // is_success: number
  bym: string,
  dt: string
}


export const columns: ColumnDef<ReportTable>[] = [
  {
    accessorKey: "sr_no",
    header: "Sr no",
    cell: ({ ...props }) => <div className='text-center'>{props?.row?.getValue('sr_no')}</div>

  },
  {
    accessorKey: "_date",
    header: "Date",
    cell: ({ ...props }) => <div className='text-center'>{props?.row?.getValue('_date')}</div>
  },
  // {
  //   accessorKey: "_type",
  //   header: "Type",
  //   cell: ({ ...props }) => <div className='text-center'>{props?.row?.getValue('_type') ? convertToCamelCase(props?.row?.getValue('_type')) : "-"}</div>
  // },
  {
    accessorKey: "feeder_name",
    header: "Feeder Name",
    cell: ({ ...props }) => <div className='text-center'>{props?.row?.getValue('feeder_name')}</div>
  },
  {
    accessorKey: "feeder_code",
    header: "Feeeder Code",
    cell: ({ ...props }) => <div className='text-center'>{props?.row?.getValue('feeder_code')}</div>
  },
  {
    accessorKey: "status",
    header: "Sync Status",
    cell: ({ ...props }) => <div className='text-center'>
      <StatusDetails rowData={props?.row?.original}></StatusDetails>
      {/* {props?.row?.getValue("status") ?
        <div className='text-green-600 text-center cursor-pointer' data-bs-toggle='tooltip' title='Synced'>
          <BadgeCheck size={20} />
        </div> :
        <div className='text-red-400 text-center cursor-pointer' data-bs-toggle='tooltip' title='Not Synced'><XCircle size={20} /></div>
      } */}
    </div>
  },
  {
    accessorKey: "latest_timestamp",
    header: "Latest Sync",
    cell: ({ ...props }) => <div className='text-center'>{props?.row?.getValue('latest_timestamp')
      ? getFormatToDateTimeWithoutSecondsTogether(props?.row?.getValue('latest_timestamp')) : "-"
    }</div>
  },

  // {
  //   id: "action",
  //   header: "Action",
  //   cell:({ ...props }) => <ActionComp rowData={props.row.original}></ActionComp>
  // }
]
