import axios, { AxiosResponse } from 'axios'
import { URL_FEEDER_CARD } from 'src/apis'


export const tripApiData = (postData: any): Promise<any> => {
  return axios
    .post(`${URL_FEEDER_CARD}`,postData)
    .then(async (d: any) => d?.data)
    .catch((e: any) => e?.response?.data)
}







