import React from 'react'
import { FeederEnergyLogDetailsState } from '../Context';
import { stringifyRequestQuery } from 'src/helpers';
import { URL_FEEDER_LOG_SHEET_EXPORT } from 'src/apis';
import { FileSpreadsheet } from 'lucide-react';
import { Button } from 'src/components/ui/button';

type Props = {}

const ExcelExport = (props: Props) => {

    const { state: MainState, updateState } = FeederEnergyLogDetailsState();
    const [isLoadingExportAPi, setisLoadingExportAPi] = React.useState<boolean>(false)

    console.log(MainState);


    const handleOnClickExport = () => {
        setisLoadingExportAPi(true)
        const newstate = MainState && JSON.parse(JSON.stringify(MainState));
        // delete newstate["_fkey"];
        delete newstate["items_per_page"];
        delete newstate["page"];
        delete newstate?.filter["mg_id"];

        delete newstate?.other["feeder_mg_id"];
        const queryString = stringifyRequestQuery({ ...newstate });
        console.log({ queryString });

        const baseUrl = URL_FEEDER_LOG_SHEET_EXPORT + "?" + queryString;
        console.log(baseUrl);
        var win: any = window.open(baseUrl, '_blank');
        win.focus();
        setisLoadingExportAPi(false)
    }

    return (
        <>
            <Button className={`mx-2 p-2 ${isLoadingExportAPi ? 'bg-secondary text-white' : 'bg-primary text-primary'} btn btn-sm btn-outline btn-outline-dashed h-30px h-md-40px flex items-center`}>
                {isLoadingExportAPi ? 'Exporting..' : <FileSpreadsheet onClick={handleOnClickExport} size={24} className={isLoadingExportAPi ? 'text-white' : 'text-white'} />}
            </Button>

        </>
    )
}

export default ExcelExport