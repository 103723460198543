import React, { useEffect, useState } from 'react'
import { FeederEnergyProjectionState } from '../Context'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../../../../../components/ui/card'
import { viewDetails } from 'src/Modules/TestComponent/Component/FeederCards/Context/core/_request'
import { convertMinutesToHoursAndMinutes, convertToCamelCase, getFormatToDateTimeWithoutSecondsTogether, getStartDateAndEndDateOfMonth, print } from '../../../../../helpers'


type Props = {}

const ProjectionBasicDetails = (props: Props) => {
    const { state, updateState: updateThisState } = FeederEnergyProjectionState()
    console.log(state);
    const [viewMore, setViewMore] = useState<any>([])
    const excludedKeys = [  'cap_unit',
    'createdAt',
    'customers_count',
    'designation',
    'district_code',
    'district_id',
    'district_name',
    'feeder_id',
    'is_change_request',
    'manager_id',
    'manager_name',
    'manager_number',
    'meter_type',
    'multi_factor',
    'sc_code',
    'sc_id',
    'sc_name',
    'state_id',
    'status',
    'type',
    'updatedAt',
    "src_substation_id",
    "src_substation_name",
    "src_substation_code",
    "src_transformer_id",
    "src_transformer_name",
    "src_transformer_code",
    "src_feeder_id",
    "is_boundary_feeder",
    "tcn_substation_id",
    "tcn_substation_name",
    "tcn_substation_code",
    "tcn_power_transformer_id",
    "tcn_power_transformer_name",
    "tcn_power_transformer_code",
    "subdivision_id",
    "state_name",
    "state_code",
    "is_active",
    "manager_phone",
    '_id'];
    const rowData = viewMore && Object.entries(viewMore).filter(([key]) => !excludedKeys.includes(key));

    useEffect(() => {
        fetchDetails()
        return () => { }
    }, [state?.other?.feeder_mg_id])

    const fetchDetails = async () => {
        if (state?.other?.feeder_mg_id) {
            const apiResp = await viewDetails(state?.other?.feeder_mg_id && state?.other?.feeder_mg_id);
            // console.log({ apiResp });
            let moreData: any = apiResp && apiResp?.data
            // console.log({ moreData });
            setViewMore(moreData)
        }

    }

    console.log(viewMore);



    return (
        <>
            <Card>
                <CardContent className='p-4'>
                    <div className='grid grid-cols-6 gap-2'>
                        {rowData && rowData?.map(([key, values]: any, index: any) => (
                            <React.Fragment key={index}>
                                <div className='col-span-1 text-sm'>{key && convertToCamelCase(key)} :</div>
                                <div className='col-span-1 text-sm font-bold'>{
                                    key == "createdAt" ? getFormatToDateTimeWithoutSecondsTogether(values) : values ? String(values) : "-"
                                }</div>
                            </React.Fragment>
                        ))}
                    </div>
                </CardContent>
            </Card>

        </>
    )
}

export default ProjectionBasicDetails