import React, { useEffect, useMemo, useState } from 'react'
import { FeederEnergyDistrictPimState, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from '../Context';
import ReactEchartsConfig from 'src/components/commons/ReactEchartsConfig';
import { getStartEndDate, getStartDateAndEndDateOfMonth, stringifyObjectToRequestQuery, getMonthRange } from 'src/helpers';
import { districtPimApiData } from '../../DailyFeederTrips/Context/core/_request';


type Props = {}

const FeederWisePIMGraph = (props: Props) => {


    const { state, updateState } = FeederEnergyDistrictPimState()
    console.log(state);

    const apiResp: any = useQueryResponseData();
    const isLoading = useQueryResponseLoading();

    const [data, setdata] = useState<any>(null)

    // useEffect(() => {
    //     fetchFeederTripsData()
    //     return () => { }
    // }, [state?.filter])


    // const fetchFeederTripsData = async () => {

    //     let newState = { ...state?.filter }
    //     console.log(newState);

    //     // const { startDate, endDate } = getStartEndDate(newState?.bym && newState?.bym);
    //     // const result = getStartEndDate(newState?.bym && newState?.bym);
    //     const result = getMonthRange(newState?.bym && newState?.bym)
    //     console.log(result?.startDate, result?.endDate);

    //     let newFilter = { district_id: newState?.district_id, start_date: result?.startDate, end_date: result?.endDate ,tcn_substation_id: newState?.tcn_substation_id,tcn_transformer_id: newState?.tcn_transformer_id }


    //     const query = stringifyObjectToRequestQuery(newFilter && newFilter);
    //     console.log(query);

    //     if (state?.filter?.bym) {
    //         const apiResp: any = await districtPimApiData(query)
    //         let chartData = apiResp?.data
    //         console.log(chartData);

    //         if (chartData) {

    //             setdata(chartData)

    //             // console.log(items);
    //         }
    //     }
    // }

    let graph_data: any = useMemo(() => apiResp || [], [apiResp]);


    // console.log({ graph_data });


    // console.log({apiResp});
    // const table_data = apiResp?.data
    // console.log({table_data});
    // setTableData({ table_data })

    let x_data = graph_data?.x?.feeder;
    let vending_pim = graph_data.y?.vending_pim
    let vended_consumers = graph_data?.y?.vended_consumers
    let postpaid_paid_consumers = graph_data?.y?.postpaid_paid_consumers
    let postpaid_pim = graph_data?.y?.postpaid_pim


    const options: any = {
        // color: colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            // right: '20%',
            bottom: '150px',
        },

        legend: {
            data: ['Postpaid PIM', 'Vending PIM', 'Postpaid Paid Consumers', 'Vended Consumers']
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    interval: 0,
                    rotate: 50,
                },
                // prettier-ignore
                data: x_data
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'PIM',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'Consumers',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: 'Postpaid Paid Consumers',
                type: 'bar',
                yAxisIndex: 1,
                data: postpaid_paid_consumers,
                color: "#A6DCBE",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            {
                name: 'Vended Consumers',
                type: 'bar',
                yAxisIndex: 1,
                data: vended_consumers,
                color: "#8EBDF6",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            {
                name: 'Postpaid PIM',
                type: 'line',
                yAxisIndex: 0,
                data: postpaid_pim,
                color: "#42E6A2",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },

            {
                name: 'Vending PIM',
                type: 'line',
                yAxisIndex: 0,
                data: vending_pim,
                color: "#438CE4",
                label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                }
            },
            // {
            //     name: 'Vending Arrears Paid',
            //     type: 'line',
            //     yAxisIndex: 1,
            //     data: vending_arrear_paid
            // },
            // {
            //     name: 'Vending Current Month Paid',
            //     type: 'line',
            //     yAxisIndex: 1,
            //     data: vending_cm_paid
            // }

        ]

    }


    return (
        <>
            {/* {isLoading && <UsersListLoadingCenter key={'test-loader'} />} */}
            <div >
                <ReactEchartsConfig option={options} loading={false} style={{ width: '1400px', height: '500px' }} />
            </div>
        </>
    )
}

export default FeederWisePIMGraph