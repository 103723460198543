import { useEffect, useState } from 'react';
import { QueryRequestProvider, QueryResponseProvider } from './Context';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../../components/ui/tabs';
import FeederProjectionGraph from './Components/FeederProjectionGraph';
import ProjectionBasicDetails from './Components/ProjectionBasicDetails';
import FeederProjectionFilters from './Components/FeederProjectionFilters';

type Props = {}

const Main = (props: Props) => {
    // const isLoading = useQueryResponseLoading();
    // const { state, updateState } = PerfHistYearlyMainState()
    // const { state: { filter } } = useMainContext();
    // const [data, setdata] = useState<any>(null)
    // const { isError, message } = useQueryResponse();
    // const [showDetails, setshowDetails] = useState<Boolean>(false);
    // const toggleDetails = () => setshowDetails(!showDetails);
    // const [isLoadingExportAPi, setisLoadingExportAPi] = useState<boolean>(false)
    // const pagination = useQueryResponsePagination();


    // const handleOnClickExport = () => {
    //     setisLoadingExportAPi(true)
    //     let table_query = { dtype: 'table' };
    //     const newstate = filter && JSON.parse(JSON.stringify(filter));
    //     // console.log({ newstate });
    //     // // delete newstate["_fkey"];
    //     // delete newstate["items_per_page"];
    //     // delete newstate["page"];
    //     table_query = { ...table_query, ...newstate }
    //     // query = stringifyObjectToRequestQuery(table_query);
    //     const queryString = stringifyObjectToRequestQuery(table_query);
    //     // console.log({ queryString });

    //     const baseUrl = URL_FEEDER_PROFILING_PERFORMANCE_HISTORY_YEARLY_EXPORT_URL + "?" + queryString;
    //     // console.log(baseUrl);
    //     var win: any = window.open(baseUrl, '_blank');
    //     win.focus();
    //     setisLoadingExportAPi(false)
    // }


    return <>

        <div className="space-y-2">
            <div className="flex justify-between items-center ">
                <div className="justify-self-start text-center font-bold font-md">Feeder Projection Dashboard</div>
                <div className='flex justify-end space-x-2 items-center'>
                    <FeederProjectionFilters />
                </div>
            </div>
            <ProjectionBasicDetails />
            <div className='text-center font-bold font-md'>Feeder Projection Analysis</div>
            <FeederProjectionGraph />
        </div>
    </>
}

const FeederProjectionMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default FeederProjectionMain