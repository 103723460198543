import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { RootState } from "../store"

interface DashboardState {
    dashBoardState: boolean,

}

const initialState: DashboardState = {
    dashBoardState: false
}

export const dashboardSlicer = createSlice({
    name: "dashboardSlicer",
    initialState,
    reducers: {
        updateOpen(state, action: PayloadAction<any>) {
            state.dashBoardState = action.payload
            // console.log(state?.dashBoardState)
        },

    },

})

export const { updateOpen } = dashboardSlicer.actions;
export const dashboardReducerState = (state: RootState) => state.dashboard;
export default dashboardSlicer.reducer;

