

// import { cn } from 'src/helpers/index';
// import { Menu, MenuOptions } from './modules';
// import { Link, useLocation } from 'react-router-dom';
// import { ChevronLeft, ChevronRight } from 'lucide-react';
// import { useState } from "react";
// import { current } from '@reduxjs/toolkit';
// import { Badge } from 'src/components/ui/badge';
// import { useDispatch, useSelector } from 'react-redux';

// import { RootState } from 'src/redux/store';
// import { dashboardReducerState, updateOpen } from 'src/redux/Reducers/dashboardSlicer';
// const Sidebarlayout = () => {
//   const [open, setOpen] = useState(true);
//   const [isHovered, setIsHovered] = useState(false);
//   const dispatch = useDispatch()
//   const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))
//   const { pathname } = useLocation()


//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };


//   const menuOptions = Menu;
//   return (
//     <div className="flex fixed">
//       <div
//         className={` ${dashBoardState ? "w-60" : "w-20 "
//           }  bg-teal-500 h-screen p-5  pt-8 relative duration-300`}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <div

//           className={`ml-2 absolute cursor-pointer -right-3 top-9 w-8 h-8 text-center mt-8 border-purple-400
//            border-2 rounded-full bg-white flex justify-center items-center`}
//           onClick={() => {
//             dispatch(updateOpen(!dashBoardState))
//             setOpen(!open)
//           }}
//         >
//           {dashBoardState ? (<ChevronLeft size={20} />) : (< ChevronRight size={20} />)}
//         </div>


//         <ul className={`pt-3  space-y-10 ${!dashBoardState ? 'space-y-3' : ''}`} style={{ display: isHovered ? 'block' : 'none' }}>
//           {menuOptions.map((Menu, index) => (
//             <div key={index} className=''>
//               <h1 className={`text-white font-semibold text-md ${!dashBoardState ? 'hidden' : ''}`}>{Menu.heading}</h1>
//               {Menu.options?.map((menuItem, itemIndex) => (
//                 <li
//                   key={itemIndex}
//                   className={`rounded-md p-2 cursor-pointer hover:bg-light-white text-white text-xs items-center 
//                   ${pathname === menuItem?.path ? 'text-white font-semibold' : ''}`}
//                 >
//                   <Link to={menuItem.path} className='flex items-center space-x-4'>
//                     <span
//                     >{menuItem.iconName(cn('text-slate-600 text-lg', { "text-white font-bolder": pathname === menuItem?.path }))}
//                     </span>

//                     <span className={`duration-300 transition-all ease-in-out origin-left text-md ${!dashBoardState ? 'hidden font-bolder' : ''}`}>
//                       {menuItem.name}
//                     </span>
//                   </Link>
//                 </li>
//               ))}
//             </div>))}
//         </ul>

//       </div>

//     </div>
//   );
// };

// export default Sidebarlayout;


import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from './modules';
import { cn } from 'src/helpers';

const SidebarLayout = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const menuOptions = Menu;

  return (
    <div className="flex fixed z-50 ">
      <div
        className={`w-60 bg-teal-500 h-screen p-3 pt-8 relative duration-300 overflow-scroll `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          width: isHovered ? '15rem' : '5rem',

          // ('/media/svg/shapes/abstract-4.svg')
        }
        }
      >
        <ul className={`pt-3 ${isHovered ? 'space-y-3' : ''}`}>
          {menuOptions.map((Menu, index) => (
            <div key={index}>
              <h1 className={`text-zinc-950 font-mono font-semibold text-lg ${isHovered ? '' : 'hidden'}`}>{Menu.heading}</h1>
              {Menu.options?.map((menuItem, itemIndex) => (
                <li
                  key={itemIndex}
                  className={`rounded-md p-2 cursor-pointer hover:bg-light-white text-black text-xs items-center ${window.location.pathname.startsWith(menuItem?.path) ? 'text-white font-semibold' : ''}`}
                >
                  <Link to={menuItem.path} className='flex items-center space-x-4'>
                    <span>{menuItem.iconName(cn('text-slate-600 text-lg', { "text-white font-bolder": window.location.pathname.startsWith(menuItem?.path) }))}</span>
                    <span className={`duration-300 transition-all ease origin-left text-sm  ${isHovered ? '' : 'hidden'}`}>
                      {menuItem.name}
                    </span>
                  </Link>
                </li>
              ))}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarLayout;
