import { useEffect, useMemo, useState } from 'react';
import { FeederEnergyTripDetailsState, QueryRequestProvider, QueryResponseProvider } from './Context';
import TripGraph from './Componets/TripGraph';
import TripStatusFilter from './Componets/TripStatusFilter';

type Props = {}

const Main = (props: Props) => {
    const { state, updateState } = FeederEnergyTripDetailsState();


    return <>
        <div className="flex justify-between items-center ">
            <div className="justify-self-start text-center font-bold font-md">Feeder Trips Dashboard</div>
            <div className='flex justify-end space-x-2 items-center'>
                <TripStatusFilter />
            </div>
        </div>
        <div className='p-2'>
            <div className='text-center font-bold font-md'>Feeder Trips</div>
            <TripGraph />
        </div>
    </>
}

const TripStatusMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default TripStatusMain