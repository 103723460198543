import React from 'react'
import { stringifyRequestQuery } from 'src/helpers';
import { URL_CRON_JOB_SUMMARY_DETAILS_EXPORT, URL_FEEDER_LOG_SHEET_EXPORT } from 'src/apis';
import { FileSpreadsheet } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { CronSummaryDetailsMainState } from '../Context';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getCronJobFilterReducerState } from 'src/redux/Reducers/cronJobReducer';

type Props = {}

const ExcelExport = (props: Props) => {

    const { state: MainState, updateState } = CronSummaryDetailsMainState();
    const [isLoadingExportAPi, setisLoadingExportAPi] = React.useState<boolean>(false)
    const { total_records, _date } = useSelector((state: RootState) => getCronJobFilterReducerState(state))

    // console.log(MainState);
    // console.log(_date)


    const handleOnClickExport = () => {
        setisLoadingExportAPi(true)
        const newstate = MainState && JSON.parse(JSON.stringify(MainState));
        // delete newstate["_fkey"];
        delete newstate["items_per_page"];
        delete newstate["page"];
        // delete newstate?.filter["mg_id"];

        // delete newstate?.other["feeder_mg_id"];
        const queryString = stringifyRequestQuery({ ...newstate, _date: _date, items_per_page: total_records });
        // console.log({ queryString });

        const baseUrl = URL_CRON_JOB_SUMMARY_DETAILS_EXPORT + "?" + queryString + "&_type=meter_reading" + `&_date=${_date}`;
        console.log(baseUrl);
        var win: any = window.open(baseUrl, '_blank');
        win.focus();
        setisLoadingExportAPi(false)
    }

    return (
        <>
            <Button className={`mx-2 p-2 ${isLoadingExportAPi ? 'bg-secondary text-white' : 'bg-primary text-primary'} btn btn-sm btn-outline btn-outline-dashed h-30px h-md-40px flex items-center`}>
                {isLoadingExportAPi ? 'Exporting..' : <FileSpreadsheet onClick={handleOnClickExport} size={24} className={isLoadingExportAPi ? 'text-white' : 'text-white'} />}
            </Button>

        </>
    )
}

export default ExcelExport