// export const login(username: string, password: string) {
//     return axios.post<AuthModel>(LOGIN_URL, {
//         username,
//         password,
//         email: null
//     }).then((d: any) => d.data)
//         .catch((e: any) => e.response?.data)
// }

import axios, { AxiosError, AxiosResponse } from "axios"
import { LOGIN_URL, PROFILE_URL } from "src/apis"
// import { axiosWithToken } from "src/modules/services/axiosSetup";
import { getAuth } from "../../Core";

console.log(LOGIN_URL)
export const loginApi = (postData: any) => {
    // console.log(postData)
    return axios
        .post(`${LOGIN_URL}`, postData)
        .then((d: AxiosResponse<any>) => d?.data)
}

export function getUserByToken(): Promise<AxiosResponse> {
   const auth = getAuth()
    return axios.get(PROFILE_URL, {
        headers : {
            'Authorization': `Bearer ${auth?.api_token}`
        }
    }).then((d: AxiosResponse) => d.data)
}