import { endOfMonth, format, startOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker';
import { DatePickerWithRange } from 'src/components/commons/DatePickerWithRange'
import { CronLoadIntervalSummaryMainState } from '../Context';
import { Button } from 'src/components/ui/button';
import { useDispatch } from 'react-redux';
import { fetchAllCronJobListAsync, setEndDate, setStartDate, setType } from 'src/redux/Reducers/cronJobReducer';

type Props = {}

const SummaryFilters = (props: Props) => {

    const { state, updateState } = CronLoadIntervalSummaryMainState()
    const currentDate = new Date();
    const startOfMonthDate = startOfMonth(currentDate);
    const endOfMonthDate = endOfMonth(currentDate);
    const dispatch = useDispatch()

    const initialDateRange: DateRange = {
        from: startOfMonthDate,
        to: endOfMonthDate,
    };
    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>(initialDateRange);

    useEffect(() => {
        if (state?.filter?.start_date && state?.filter?.end_date) {
            // console.log(state?.filter?.start_date, state?.filter?.end_date);
            setSelectedDate({
                from: new Date(state?.filter?.start_date),
                to: new Date(state?.filter?.end_date),
            })
        }
        return () => { }
    }, [state?.filter?.start_date, state?.filter?.end_date])

    console.log(selectedDate, state );


    const handleDateSelect = (selectedRange: DateRange | undefined) => {
        setSelectedDate(selectedRange);
        const formattedStartDate = selectedRange?.from ? format (selectedRange?.from, "yyyy-MM-dd") : '';
        const formattedEndDate = selectedRange?.to ? format(selectedRange?.to, "yyyy-MM-dd") : '';

        dispatch(setStartDate(formattedStartDate))
        dispatch(setEndDate(formattedEndDate))
        // Perform any actions needed with the selected date range
    };

    const handleApplyFiltes = () => {
        dispatch(setType("load_interval"))
        // dispatch(setStartDate(start_date))
        // dispatch(setEndDate(end_date))
        dispatch(fetchAllCronJobListAsync())
    }

    return (
        <>
            <DatePickerWithRange selectedDate={selectedDate} onDateSelect={handleDateSelect} />
            <Button onClick={handleApplyFiltes} >Submit</Button>
        </>
    )
}

export default SummaryFilters