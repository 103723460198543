import { useEffect, useState } from 'react';
import { FeederEnergyDailyDistrictTripsState, QueryRequestProvider, QueryResponseProvider, useQueryResponse } from './Context';
import DistrictFilter from 'src/Modules/Filters/Districts';
import { endOfDay, endOfMonth, format, startOfDay, startOfMonth } from 'date-fns';
import DateBymFilter from 'src/components/commons/DateBymFilter';
import { DatePickerWithRange } from 'src/components/commons/DatePickerWithRange';
import { DateRange } from 'react-day-picker';
import DailyFeederTripsGraph from './Components/DailyFeederTripsGraph';
import { toast } from 'react-toastify';
import { SelectDropdown } from 'src/components/commons/DropdownSelect';
import SubStationFilter from 'src/Modules/Filters/Substation';
import { Button } from 'src/components/ui/button';
import TransformerFilter from 'src/Modules/Filters/Transformer';



type Props = {}

const dropdownOptions = [
    { value: 'dist', label: 'District' },
    { value: 'substn', label: 'Substation' },
]

const Main = (props: Props) => {

    const { state, updateState } = FeederEnergyDailyDistrictTripsState();
    const { isError, message, response } = useQueryResponse();
    const currentDate = new Date();
    const startOfMonthDate = startOfMonth(currentDate);
    const endOfMonthDate = endOfMonth(currentDate);

    const [filterType, setfilterType] = useState<any>("")
    const [filterData, setfilterData] = useState<any>({
        district_id: "",
        tcn_substation_id: "",
        start_date: startOfMonthDate,
        end_date: endOfMonthDate,
    })

    console.log(filterData)

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        return () => { }
    }, [isError])



    const initialDateRange: DateRange = {
        from: startOfMonthDate,
        to: endOfMonthDate,
    };

    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>(initialDateRange);

    const handleDateSelect = (selectedRange: DateRange | undefined) => {
        setSelectedDate(selectedRange);
        // Perform any actions needed with the selected date range
    };


    useEffect(() => {
        if (selectedDate?.to && selectedDate?.from) {
            setfilterData({
                ...filterData,
                start_date: format(selectedDate?.from, "yyyy-MM-dd"),
                end_date: format(selectedDate?.to, "yyyy-MM-dd")
            });
        }
    }, [selectedDate]);


    // console.log(response);

    const handleApplyFiltes = () => {
        let newFilter = { ...state.filter, ...filterData }
        updateState({ ...state, filter: newFilter })
    }

    // console.log(state);

    // console.log(state, monthFilter, currentYear);
    const handleSelect = (selectedValue: string) => {
        // Handle the selected value
        // console.log('Selected value:', selectedValue);
        setfilterType(selectedValue)

    };


    return <>
        <div className='space-y-2'>
            <div className="flex justify-between items-center ">
                <div className="justify-self-start text-center font-bold font-md">Feeder Trip Dashboard</div>
                <div className='flex justify-end items-center space-x-2 space-y-2 mb-2'>
                    <div className='mt-2'>
                        <SelectDropdown options={dropdownOptions} onSelect={handleSelect} value={filterType} />
                    </div>
                    {filterType && filterType == "dist" ?
                        <DistrictFilter value={filterData.district_id} onChange={(e: any) => {
                            setfilterData({ ...filterData, district_id: e, tcn_substation_id: "", tcn_transformer_id: "" })
                            //    console.log({e})
                            // refetch()
                        }} /> : <></>}
                    {filterType && filterType == "substn" ? <>
                        <SubStationFilter value={filterData?.tcn_substation_id} onChange={(e: any) => {
                            setfilterData({ ...filterData, tcn_substation_id: e, district_id: "" })
                            // refetch()
                        }} />
                        <TransformerFilter value={filterData?.tcn_transformer_id} substation_id={filterData?.tcn_substation_id} onChange={(e: any) => {
                            setfilterData({ ...filterData, tcn_transformer_id: e, district_id: "" })
                            // refetch()
                        }} /></>
                        : <></>
                    }
                    <DatePickerWithRange selectedDate={selectedDate} onDateSelect={handleDateSelect} />
                    {/* <DateBymFilter setMonthFilter={(e: any) => setfilterData({ ...filterData, bym: e })} monthYear={filterData.bym} /> */}
                    <Button onClick={handleApplyFiltes} >Submit</Button>
                </div>
            </div>

            {/* <div className='flex justify-end items-center space-x-2 space-y-2 mb-2'>
                <DistrictFilter onChange={(e: any) => {
                    updateState({ ...state, filter: { ...state?.filter, district_id: e } })
                    // refetch()
                }} />
                
            </div> */}
            <div className='text-center font-bold font-md'>Feeder Trip Analysis</div>
            <DailyFeederTripsGraph />
        </div>
    </>
}

const DailyFeederTripsMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>

}

export default DailyFeederTripsMain