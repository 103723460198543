import axios, { AxiosResponse } from 'axios'
import { URL_FEEDER_CARD, URL_VIEW_MORE_DETAILS } from 'src/apis'


export const apiData = (query: string, postData: any): Promise<any> => {
  return axios
    .post(`${URL_FEEDER_CARD}?${query}`, postData)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response?.data)
}

export const viewDetails = (id: string): Promise<any> => {
  return axios
    .get(`${URL_VIEW_MORE_DETAILS(id)}`)
    .then(async (d: any) => d.data)
    .catch((e: any) => e?.response)
}








