import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { QueryState, stringifyObjectToRequestQuery, stringifyRequestQuery } from 'src/helpers';
import axios, { AxiosError } from 'axios';
import { cronJobSummaryApiData } from 'src/Modules/TestComponent/Component/CronJob/Summary/Context/core/_request';
import { cronJobSummaryDetailsApiData } from 'src/Modules/TestComponent/Component/CronJob/SummaryDetails/Context/core/_request';



interface ICronJobReducerState extends QueryState {
    isLoading: boolean,
    error: boolean,
    message: string | undefined,
    tableData: any[],
    summaryData: any[],
    request_status: undefined | number,
    status: undefined | string,
    req_no: string | undefined,
    code: string | undefined,
    name: string | undefined,
    district_id: string,
    feeder_id: string,
    start_date: string,
    end_date: string,
    _type: string,
    _date: string,
    page: number
    items_per_page: 10 | 30 | 50 | 100
}


export const initaiState: ICronJobReducerState = {
    isLoading: false,
    error: false,
    message: undefined,
    tableData: [],
    summaryData: [],
    request_status: undefined,
    status: undefined,
    req_no: undefined,
    code: undefined,
    name: undefined,
    district_id: "",
    feeder_id: "",
    start_date: "",
    end_date: "",
    _type: "",
    _date: "",
    page: 1,
    items_per_page: 30,
}


const transformToQueryString = (data: any) => {

    // console.log({ data });


    const filterkeys: string[] = ["_type", "start_date", "end_date", "_date"];
    const f_keys: string[] = ["status"]
    const q_keys: string[] = ["req_no", "code", "name"];

    let filters: any = {};
    let _f: any = {};
    let _q: any = {};

    data && Object.entries(data).map(([key, value]) => {
        if (filterkeys.length > 0 && filterkeys.includes(key)) {
            filters[key] = value
        }
        else if (f_keys.length > 0 && f_keys.includes(key)) {
            _f[key] = value
        }
        else if (q_keys.length > 0 && q_keys.includes(key)) {
            _q[key] = value
        }
    })

    // console.log({ filters, _f, _q });


    return stringifyRequestQuery({
        filter: filters,
        f: _f,
        q: _q,
        items_per_page: data.items_per_page,
        page: data.page
    })

}


export const fetchAllCronJobListAsync: any = createAsyncThunk(
    'AllCronJobListReducer/fetchAll',
    async (_, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().cronJobFilterState
            console.log(getFilterState);
            const newFilterState = { ...getFilterState }
            delete newFilterState["_date"]

            const queryString = transformToQueryString(newFilterState);
            console.log({ queryString })
            // if(getFilterState?.startDate && getFilterState?.endDate)


            let response: any = await cronJobSummaryApiData(queryString);


            if (response.code === 200 && response.data) {
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = "";//response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const fetchAllCronJobListSummaryAsync: any = createAsyncThunk(
    'AllCronJobListSummaryReducer/fetchAll',
    async (_, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().cronJobFilterState
            console.log(getFilterState);
            const newFilterState = { ...getFilterState }
            delete newFilterState["start_date"]
            delete newFilterState["end_date"]
            const queryString = transformToQueryString(newFilterState);
            console.log({ queryString })
            // if(getFilterState?.startDate && getFilterState?.endDate)


            let response: any = await cronJobSummaryDetailsApiData(queryString);


            if (response.code === 200 && response.data) {
                console.log(response)
                return response?.data; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = "";//response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch errorr with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);




export const cronJobReducer = createSlice({
    name: 'cronJobReducer',
    initialState: initaiState,
    reducers: {
        setStartDate: (state, action: PayloadAction<any>) => {
            state.start_date = action.payload;
        },
        setEndDate: (state, action: PayloadAction<any>) => {
            state.end_date = action.payload;
        },
        setType: (state, action: PayloadAction<any>) => {
            state._type = action.payload;
        },
        setDate: (state, action: PayloadAction<any>) => {
            state._date = action.payload;
        },
        setPage: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
        setItemsPerPage: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload;
        },
        setPaggination: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload.pageSize;
            state.page = action.payload.pageIndex;
        },
        setFilters: (state, action: PayloadAction<{
            end_date: string,
            start_date: string,
            _type: string,
        }>) => {
            state.end_date = action.payload.end_date;
            state.start_date = action.payload.start_date;
            state._type = action.payload._type;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload?.data?.message;
            state.request_status = action.payload?.status;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        reseteState: (state) => {
            state = initaiState
        },
        setQKeys: (state, action: PayloadAction<{

            req_no: string | undefined,
            code: string | undefined,
            name: string | undefined,
        }>) => {
            state.req_no = action.payload.req_no;
            state.code = action.payload.code;
            state.name = action.payload.name;
            console.log(state?.name);

        },
        setStatus: (state, action: PayloadAction<any>) => {
            state.status = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCronJobListAsync.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.tableData = payload.data;
                state.error = false;
                state.message = undefined;
                state.items_per_page = payload?.payload?.pagination?.items_per_page;
                state.page = payload?.payload?.pagination?.page;
                state.total_records = payload?.payload?.pagination?.total_records;
                state.total = payload?.payload?.pagination?.total;
            })
            .addCase(fetchAllCronJobListAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.tableData = []
                state.isLoading = true
            })
            .addCase(fetchAllCronJobListAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })

            .addCase(fetchAllCronJobListSummaryAsync.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.summaryData = payload.data;
                state.error = false;
                state.message = undefined;
                state.items_per_page = payload?.payload?.pagination?.items_per_page;
                state.page = payload?.payload?.pagination?.page;
                state.total_records = payload?.payload?.pagination?.total_records;
                state.total = payload?.payload?.pagination?.total;
            })
            .addCase(fetchAllCronJobListSummaryAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.summaryData = []
                state.isLoading = true
            })
            .addCase(fetchAllCronJobListSummaryAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })
    }
});

export const { setStartDate, setEndDate, setType, setError, resetError, reseteState, setItemsPerPage, setPage, setPaggination, setFilters, setQKeys, setStatus, setDate } = cronJobReducer.actions;

export const getCronJobFilterReducerState = (state: RootState) => state.cronJobFilterState;

export default cronJobReducer.reducer;