import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { stringifyObjectToRequestQuery } from '@/helper/index';
import axios, { AxiosError } from 'axios';
import { tripApiData } from 'src/Modules/TestComponent/Component/TripStatus/Context/core/_request';

interface IFeederTripsReducerState {
    isLoading: boolean,
    error: boolean,
    message: string | undefined,
    tableData: any[],
    status: undefined | number
}


export const initaiState: IFeederTripsReducerState = {
    isLoading: false,
    error: false,
    message: undefined,
    tableData: [],
    status: undefined
}

export const fetchFeederTripsAsync: any = createAsyncThunk(
    'feederTripsReducer/fetchAll',
    async (_, thunkApi: any) => {
        try {

            const getFilterState = thunkApi.getState().filterState
            const postData = {
                "district_id": getFilterState.district_id || null,
                "tcn_substation_id": getFilterState.tcn_substation_id || null,
                "_date": getFilterState._date || undefined,
                "dtype": "chart"
            }
            const response: any = await tripApiData(postData);

            console.log({ response })

            if (response.code === 200 && response.data) {
                return response; // Resolve the Promise with the successful response
            }
            else {
                const errorMessage = "";//response.data?.message || 'An error occurred during login';
                return thunkApi.rejectWithValue(errorMessage);
            }

        } catch (_error) {
            // Handle other errors, such as network errors
            const error = _error as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                console.log("catch error with axios");
                thunkApi.dispatch(setError(error.response?.data.message));
                return thunkApi.rejectWithValue(error.response?.data.message);
            }
            thunkApi.dispatch(setError(error.message));
            return thunkApi.rejectWithValue(error.message);
        }
    }
);


export const feederTripsReducer = createSlice({
    name: 'feederTripsReducer',
    initialState: initaiState,
    reducers: {

        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload?.data?.message;
            state.status = action.payload?.status;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        reseteState: (state) => {
            state = initaiState
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeederTripsAsync.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.tableData = payload.data;
                state.error = false;
                state.message = undefined
            })
            .addCase(fetchFeederTripsAsync.pending, (state) => {
                state.error = false;
                state.message = "";
                state.tableData = []
                state.isLoading = true
            })
            .addCase(fetchFeederTripsAsync.rejected, (state, { payload }) => {
                state.error = true;
                state.message = payload;
                state.isLoading = false;
            })
    }
});

export const { resetError, setError, reseteState } = feederTripsReducer.actions;

export const getfeederTripsReducerState = (state: RootState) => state.feederTripsReducerState;

export default feederTripsReducer.reducer;