import { PieChart, PieSeriesOption, BarChart, BarSeriesOption,LineChart,LineSeriesOption } from "echarts/charts";
import {
    LegendComponent,
    LegendComponentOption,
    TooltipComponent,
    TooltipComponentOption,
    GridComponent,
    GridComponentOption,
    DatasetComponent,
    DatasetComponentOption,
    ToolboxComponent,
    ToolboxComponentOption,
} from "echarts/components";
import { ComposeOption, ECharts, SetOptionOpts, getInstanceByDom, init, use } from "echarts/core";
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from "echarts/renderers";
import { CSSProperties, useEffect, useRef, useState } from 'react';
// import PulseLoaderComponent from "../commons/PulseLoaderComponent";


// Register the required components
use([
    DatasetComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    BarChart,
    ToolboxComponent,
    TooltipComponent,
    LineChart
]);


// Combine an Option type with only required components and charts via ComposeOption
export type EChartsOption = ComposeOption<
    | PieSeriesOption | TooltipComponentOption | LegendComponentOption | BarSeriesOption | GridComponentOption | DatasetComponentOption |LineSeriesOption  | ToolboxComponentOption
>;

export interface ReactEChartsProps {
    option: EChartsOption;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    loading?: boolean;
    theme?: "light" | "dark";
}


const ReactEchartsConfig = ({
    option,
    style,
    settings,
    loading,
    theme,
}: ReactEChartsProps) => {

    const [isLoading, setisLoading] = useState<any>(false)

    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Initialize chart
        let chart: ECharts | undefined;
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme);
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [theme]);

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart?.setOption(option, settings);
        }
    }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    useEffect(() => {
        // Update chart
        // if (chartRef.current !== null) {
        //     const chart = getInstanceByDom(chartRef.current);
        //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        //     loading === true ? chart?.showLoading() : chart?.hideLoading();
        // }

        setisLoading(loading);

    }, [loading, theme]);


    return (

        <div className="position-relative">
            <div ref={chartRef} style={{  ...style, filter: isLoading ? 'grayscale(1)' : 'grayscale(0)', opacity: isLoading ? '0.5' : '1' }} />
            {/* <div className="position-absolute " style={{ transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>
                {isLoading == true ? <PulseLoaderComponent isLoading={isLoading} /> : null}
            </div> */}
        </div>

    )
}

export default ReactEchartsConfig
