// import React from "react";
// import { addMonths, format, startOfMonth, endOfMonth } from "date-fns";
// import { Calendar as CalendarIcon } from "lucide-react";
// import { DateRange } from "react-day-picker";
// import { cn } from "src/helpers";
// import { Button } from "../../components/ui/button";
// import { Calendar } from "../../components/ui/calendar";
// import {
//   Popover,
//   PopoverContent,
//   PopoverTrigger,
// } from "../../components/ui/popover";

// type Props = {
//   className?: string;
//   selectedDate?: DateRange | undefined;
//   onDateSelect: (selectedDate: DateRange | undefined) => void;
// };

// export function DatePickerWithRange({
//   className,
//   selectedDate,
//   onDateSelect,
// }: Props) {
//   const [date, setDate] = React.useState<DateRange | undefined>(selectedDate);

//   const handleDateSelect = (selectedRange: DateRange | undefined) => {
//     if (selectedRange && selectedRange.from) {
//       if (selectedRange.to) {
//         selectedRange.to = endOfMonth(selectedRange.from);
//       } else {
//         selectedRange.to = endOfMonth(selectedRange.from);
//       }
//     }

//     setDate(selectedRange);
//     onDateSelect(selectedRange);
//   };

//   const fromMonth = date && date.from ? date.from : new Date();
//   const toMonth = date && date.to ? date.to : addMonths(fromMonth, 1);

//   return (
//     <div className={cn("grid gap-2", className)}>
//       <Popover>
//         <PopoverTrigger asChild>
//           <Button
//             id="date"
//             variant={"outline"}
//             className={cn(
//               "w-[300px] justify-start text-left font-normal",
//               !date && "text-muted-foreground"
//             )}
//           >
//             <CalendarIcon className="mr-2 h-4 w-4" />
//             {date?.from ? (
//               date.to ? (
//                 <>
//                   {format(date.from, "yyyy-MM-dd")} -{" "}
//                   {format(date.to, "yyyy-MM-dd")}
//                 </>
//               ) : (
//                 format(date.from, "yyyy-MM-dd")
//               )
//             ) : (
//               <span>Pick a date-range</span>
//             )}
//           </Button>
//         </PopoverTrigger>
//         <PopoverContent className="w-auto p-0" align="start">
//           <Calendar
//             initialFocus
//             mode="range"
//             defaultMonth={fromMonth}
//             selected={date}
//             onSelect={handleDateSelect}
//             numberOfMonths={1} // Set the number of months to display
//             fromMonth={fromMonth}
//             toMonth={toMonth}
//           />
//         </PopoverContent>
//       </Popover>
//     </div>
//   );
// }

import React, { useEffect } from "react";
import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "src/helpers";
import { Button } from "../..//components/ui/button";
import { Calendar } from "../..//components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";

type Props = {
  className?: string;
  selectedDate?: DateRange | undefined;
  onDateSelect: (selectedDate: DateRange | undefined) => void;
};

export function DatePickerWithRange({
  className,
  selectedDate,
  onDateSelect,
}: Props) {

  const [date, setDate] = React.useState<DateRange | undefined>(selectedDate || {
    from: undefined,
    to: undefined,
  });

  useEffect(() => {
    setDate(selectedDate)
    return () => { }
  }, [selectedDate])


  const handleDateSelect = (selectedRange: DateRange | undefined) => {
    setDate(selectedRange);
    onDateSelect(selectedRange);
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from || new Date()} // Set default month to 'from' date or current month if 'from' is not set
            selected={date}
            onSelect={handleDateSelect}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

